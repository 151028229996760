/**
 * @user simxin
 * @desc Context 组件
 */

import React, { createContext, useReducer, useContext } from 'react';
import reducer from './reducer';
import store from './store';

const DetailContext = createContext<{
  state: typeof store;
  dispatch: React.Dispatch<any>;
}>({ state: store, dispatch: () => null });

type DetailProviderProps = {
  children: React.ReactNode;
};

const DetailProvider = ({ children }: DetailProviderProps) => {
  const [state, dispatch] = useReducer(reducer, store);
  return <DetailContext.Provider value={{ state, dispatch }}>{children}</DetailContext.Provider>;
};

const useStore = () => {
  const { state } = useContext(DetailContext);
  return state;
};

const useDispatch = () => {
  const { dispatch } = useContext(DetailContext);
  return (type: any, value: any) => dispatch({ type, value });
};

export { DetailContext, DetailProvider, useStore, useDispatch };
