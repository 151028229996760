/**
 * @user simxin
 * @desc 图片容错组件
 */

import React, { useState, useMemo } from 'react';
import { CatchPic } from '../config';

type EleCatchPicProps = {
  src: string;
  alt?: string;
  className?: string;
};

const EleCatchPic = ({ src = '', alt = '', className = '' }: EleCatchPicProps) => {
  const [loadErr, setLoadErr] = useState(false);
  return useMemo(
    () => <img className={className} src={loadErr ? CatchPic : src} alt={alt} onError={() => setLoadErr(true)} />,
    [loadErr, src, alt, className],
  );
};

export default React.memo(EleCatchPic);
