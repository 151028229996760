import React, { useMemo, useState } from 'react';
import { formatTimePoint } from '../common/util';
import { useStore } from '../dataflow/context';
import { AuditTimelineItem, StoreType } from '../interface';
import { optionMap } from './AuditTimeline';
import EleVideoClip from './EleVideoClip';

function AuditList() {
  const [current, setCurrent] = useState(-1);
  const { player, auditTimeline, auditFilterSet, auditFilterType, auditFilterSuggestion } = useStore();
  const key: keyof StoreType['auditTimeline'] = `${auditFilterSet}_${auditFilterType}_${auditFilterSuggestion}`;
  const typeList = useMemo(() => {
    setCurrent(-1);
    return auditTimeline[key];
  }, [auditTimeline, key]);
  return typeList?.length ? (
    <div className="section list-box">
      {typeList?.map((item: AuditTimelineItem<any>, index) => (
        <EleVideoClip
          key={key + index}
          cover={item.url}
          select={current === index}
          onClick={() => {
            console.log(item);

            setCurrent(index);
            player?.currentTime(item.startTime);
          }}
          title={
            item.name ||
            item.keyWords?.join('&') ||
            optionMap[auditFilterType].find(({ value }) => value === item.label)?.label!
          }
          descs={[
            { label: '出现时间', value: formatTimePoint(item.startTime) },
            { label: '置信度', value: String(item.confidence) },
          ]}
        />
      ))}
    </div>
  ) : null;
}

export default React.memo(AuditList);
