/**
 * @user simxin
 * @desc 数据转换
 */

import { StoreType, StringKVObject, AnalyTagWithLabelItem, RecogDetailCtx } from '../interface.d';

// 处理 eleVideoPlayer
export function translateEleVideoPlayer(file: AiSession.CommonFile) {
  return {
    url: file.SourceVideoUrl,
    duration: file.MetaData.Duration,
    width: file.MetaData.Width,
    height: file.MetaData.Height,
  };
}

// 处理 analyMeta
export function translateAnalyMeta(file: AiSession.CommonFile) {
  const analyMeta: StoreType['analyMeta'] = {
    classes: [],
    tags: [],
    covers: [],
  };

  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'Classification') {
      item.ClassificationTask.Output?.ClassificationSet?.forEach(set => {
        analyMeta.classes.push(set.Classification);
      });
    }
    if (item.Type === 'Tag') {
      item.TagTask.Output?.TagSet?.forEach(set => {
        analyMeta.tags.push(set.Tag);
      });
    }
    if (item.Type === 'Cover') {
      item.CoverTask.Output?.CoverSet?.forEach(set => {
        analyMeta.covers.push(set.CoverPath);
      });
    }
  });
  return analyMeta;
}

// 处理 recogFace
export function translateRecogFace(
  file: AiSession.CommonFile,
  defaultFaceUrls: StringKVObject,
  userDefineFaceUrls: StringKVObject,
) {
  const recogFaceCtx: StoreType['recogFace']['recogDetailCtx'] = [];
  const FaceTask = file.AiRecognitionResultSet.find(item => item.Type === 'FaceRecognition')?.FaceTask;
  const FaceTaskOutput = FaceTask?.Output?.ResultSet || [];
  FaceTaskOutput.forEach(set => {
    let url = '';
    if (set.Type === 'Default') {
      url = set.Url || defaultFaceUrls[set.Id];
    }
    if (set.Type === 'UserDefine') {
      url = set.Url || userDefineFaceUrls[set.Id];
    }
    recogFaceCtx.push({
      id: set.Id,
      name: set.Name,
      url,
      segments: set.SegmentSet.map(segment => ({
        startTime: segment.StartTimeOffset,
        endTime: segment.EndTimeOffset,
        confidence: segment.Confidence,
        coords: segment.AreaCoordSet,
      })),
    });
  });

  return { status: FaceTask?.Status!, recogDetailCtx: recogFaceCtx };
}

// 处理 recogFaceId
export function translateRecogFaceId(file: AiSession.CommonFile) {
  let recogFaceId: StoreType['recogFaceId'] = '';
  file.AiRecognitionResultSet.forEach(item => {
    if (item.Type === 'FaceRecognition') {
      recogFaceId = item.FaceTask.Output?.ResultSet?.[0].Id;
    }
  });
  return recogFaceId;
}

// 处理 recogObject
export function translateRecogObject(file: AiSession.CommonFile) {
  const ObjectTask = file.AiRecognitionResultSet.find(item => item.Type === 'ObjectRecognition')?.ObjectTask;
  const ObjectTaskOutput = ObjectTask?.Output?.ResultSet || [];
  const list = ObjectTaskOutput.map(
    (item, index) =>
      ({
        id: String(index),
        name: item.Name,
        url: item.ObjectUrl,
        segments: item?.SegmentSet?.map(item => ({
          startTime: item.StartTimeOffset!,
          endTime: item.EndTimeOffset,
          confidence: item.Confidence,
          coords: item.AreaCoordSet,
        })),
      } as RecogDetailCtx),
  );
  return {
    status: ObjectTask?.Status!,
    recogDetailCtx: list,
  };
}

// todo 接口暂未支持
// 处理 recogObjectId
export function translateRecogObjectId(file: AiSession.CommonFile) {
  return '0';
}

// 处理 recogSubtitlePath
export function translateRecogSubtitlePath(file: AiSession.CommonFile) {
  let recogSubtitlePath = '';
  file.AiRecognitionResultSet.forEach(item => {
    if (item.AsrFullTextTask) {
      recogSubtitlePath = item.AsrFullTextTask.Output?.SubtitlePath;
    }
  });
  return recogSubtitlePath;
}

// 处理 recogVoiceTotal
export function translateRecogVoiceTotal(file: AiSession.CommonFile) {
  let recogVoiceTotal: StoreType['recogVoiceTotal'] = [];
  file.AiRecognitionResultSet.forEach(item => {
    if (item.Type === 'AsrFullTextRecognition') {
      item.AsrFullTextTask.Output?.SegmentSet?.forEach(set => {
        recogVoiceTotal.push({
          startTime: set.StartTimeOffset,
          endTime: set.EndTimeOffset,
          confidence: set.Confidence,
          text: set.Text,
        });
      });
    }
  });
  return recogVoiceTotal;
}

// 处理 recogVoiceSnippet
export function translateRecogVoiceSnippet(file: AiSession.CommonFile) {
  let recogVoiceSnippet: StoreType['recogVoiceSnippet'] = [];
  file.AiRecognitionResultSet.forEach(item => {
    if (item.Type === 'AsrWordsRecognition') {
      item.AsrWordsTask.Output?.ResultSet?.forEach(set => {
        recogVoiceSnippet.push({
          word: set.Word,
          segments: set.SegmentSet.map(segment => ({
            confidence: segment.Confidence,
            startTime: segment.StartTimeOffset,
            endTime: segment.EndTimeOffset,
          })),
        });
      });
    }
  });
  return recogVoiceSnippet;
}

// 处理 recogTextTotal
export function translateRecogTextTotal(file: AiSession.CommonFile) {
  let recogTextTotal: StoreType['recogTextTotal'] = [];
  file.AiRecognitionResultSet.forEach(item => {
    if (item.Type === 'OcrFullTextRecognition') {
      item.OcrFullTextTask.Output?.SegmentSet?.forEach(set => {
        recogTextTotal.push({
          startTime: set.StartTimeOffset,
          endTime: set.EndTimeOffset,
          texts: set.TextSet.map(text => ({
            confidence: text.Confidence,
            coords: text.AreaCoordSet,
            text: text.Text,
          })),
        });
      });
    }
  });
  return recogTextTotal;
}

// 处理 recogTextSnippet
export function translateRecogTextSnippet(file: AiSession.CommonFile) {
  let recogTextSnippet: StoreType['recogTextSnippet'] = [];
  file.AiRecognitionResultSet.forEach(item => {
    if (item.Type === 'OcrWordsRecognition') {
      item.OcrWordsTask.Output?.ResultSet?.forEach(set => {
        recogTextSnippet.push({
          word: set.Word,
          segments: set.SegmentSet.map(segment => ({
            confidence: segment.Confidence,
            startTime: segment.StartTimeOffset,
            endTime: segment.EndTimeOffset,
          })),
        });
      });
    }
  });
  return recogTextSnippet;
}

// 处理 analyTagWithTime
export function translateAnalyTagWithTime(file: AiSession.CommonFile) {
  let analyTagWithTime: StoreType['analyTagWithTime'] = [];
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'FrameTag') {
      item.FrameTagTask.Output?.SegmentSet?.forEach(set => {
        analyTagWithTime.push({
          startTime: set.StartTimeOffset,
          endTime: set.EndTimeOffset,
          tags: set.TagSet.map(tag => ({
            tag: tag.Tag,
            confidence: tag.Confidence,
          })),
        });
      });
    }
  });
  return analyTagWithTime;
}

// 处理 analyTagWithLabel
export function translateAnalyTagWithLabel(file: AiSession.CommonFile) {
  let analyTagWithLabel: StoreType['analyTagWithLabel'] = [];
  let analyTagWithLabelMapWithTag: { [key: string]: AnalyTagWithLabelItem } = {};
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'FrameTag') {
      item.FrameTagTask.Output?.SegmentSet?.forEach(set => {
        set.TagSet.forEach(tagInfo => {
          if (analyTagWithLabelMapWithTag[tagInfo.Tag]) {
            analyTagWithLabelMapWithTag[tagInfo.Tag].times.push({
              confidence: tagInfo.Confidence,
              startTime: set.StartTimeOffset,
              endTime: set.EndTimeOffset,
            });
          } else {
            analyTagWithLabelMapWithTag[tagInfo.Tag] = {
              tag: tagInfo.Tag,
              times: [
                {
                  confidence: tagInfo.Confidence,
                  startTime: set.StartTimeOffset,
                  endTime: set.EndTimeOffset,
                },
              ],
            };
          }
        });
      });
    }
  });
  analyTagWithLabel = Object.values(analyTagWithLabelMapWithTag);
  return analyTagWithLabel;
}

// 处理 analyStrip
export function translateAnalyStrip(file: AiSession.CommonFile) {
  let analyStrip: StoreType['analyStrip'] = [];
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'SegmentRecognition') {
      item.SegmentTask.Output?.SegmentSet.forEach(set => {
        analyStrip.push({
          cover: set.CovImgUrl,
          url: set.SegmentUrl,
          startTime: set.StartTimeOffset,
          endTime: set.EndTimeOffset,
          confidence: set.Confidence,
        });
      });
    }
  });
  return analyStrip;
}

// 处理 analyHighlight
export function translateAnalyHighlight(file: AiSession.CommonFile) {
  let analyHighlight: StoreType['analyHighlight'] = [];
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'Highlight') {
      item.HighlightTask.Output?.HighlightSet?.forEach(set => {
        analyHighlight.push({
          cover: set.CovImgUrl,
          url: set.HighlightUrl,
          duration: set.Duration,
          confidence: set.Confidence,
        });
      });
    }
  });
  return analyHighlight;
}

// 处理 analyCreditsTitle
export function translateAnalyCreditsTitle(file: AiSession.CommonFile) {
  let analyCreditsTitle: StoreType['analyCreditsTitle'] = null;
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'HeadTailRecognition') {
      const { HeadConfidence, HeadTimeOffset, TailConfidence, TailTimeOffset } = item.HeadTailTask.Output || {};
      analyCreditsTitle = {
        hConfidence: HeadConfidence,
        hTime: HeadTimeOffset,
        tConfidence: TailConfidence,
        tTime: TailTimeOffset,
      };
    }
  });
  return analyCreditsTitle;
}

// todo 接口暂未支持
// 处理 analyCopyright
export function translateAnalyCopyright(file: AiSession.CommonFile) {
  let analyCopyright: StoreType['analyCopyright'] = '';
  return analyCopyright;
}

// 处理 analyAbstract
export function translateAnalyAbstract(file: AiSession.CommonFile) {
  let analyAbstract: StoreType['analyAbstract'] = [];
  file.AiAnalysisResultSet.forEach(item => {
    if (item.Type === 'Description') {
      item.DescriptionTask.Output?.DescriptionSet.forEach(set => {
        analyAbstract.push(set.Description);
      });
    }
  });
  return analyAbstract;
}

// 处理 auditTimeline
export function translateAuditTimeline(file: AiSession.CommonFile) {
  let auditTimeline: StoreType['auditTimeline'] = {
    img_porn_block: [],
    img_porn_review: [],
    img_terrorism_block: [],
    img_terrorism_review: [],
    img_political_block: [],
    img_political_review: [],
    asr_porn_block: [],
    asr_porn_review: [],
    asr_terrorism_block: [],
    asr_terrorism_review: [],
    asr_political_block: [],
    asr_political_review: [],
    ocr_porn_block: [],
    ocr_porn_review: [],
    ocr_terrorism_block: [],
    ocr_terrorism_review: [],
    ocr_political_block: [],
    ocr_political_review: [],
  };
  file.AiContentReviewResultSet?.forEach(item => {
    if (item.Type === 'Porn') {
      item.PornTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.img_porn_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: set.Label as AiSession.PornLabel,
            url: set.Url,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.img_porn_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: set.Label as AiSession.PornLabel,
            url: set.Url,
          });
        }
      });
    }
    if (item.Type === 'Porn.Ocr') {
      item.PornOcrTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.ocr_porn_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            coords: set.AreaCoordSet,
            url: set.Url,
            keyWords: set.KeywordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.ocr_porn_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            coords: set.AreaCoordSet,
            url: set.Url,
            keyWords: set.KeywordSet,
          });
        }
      });
    }
    if (item.Type === 'Porn.Asr') {
      item.PornAsrTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.asr_porn_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.asr_porn_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
          });
        }
      });
    }
    if (item.Type === 'Terrorism') {
      item.TerrorismTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.img_terrorism_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: set.Label as AiSession.TerrorismLabel,
            url: set.Url,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.img_terrorism_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: set.Label as AiSession.TerrorismLabel,
            url: set.Url,
          });
        }
      });
    }
    if (item.Type === 'Terrorism.Ocr') {
      item.TerrorismOcrTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.ocr_terrorism_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
            coords: set.AreaCoordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.ocr_terrorism_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
            coords: set.AreaCoordSet,
          });
        }
      });
    }
    if (item.Type === 'Political') {
      item.PoliticalTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.img_political_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: item.PoliticalTask?.Output.Label as AiSession.PoliticalLabel,
            subLable: set.Label,
            name: set.Name,
            url: set.Url,
            coords: set.AreaCoordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.img_political_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            label: item.PoliticalTask?.Output.Label as AiSession.PoliticalLabel,
            subLable: set.Label,
            name: set.Name,
            url: set.Url,
            coords: set.AreaCoordSet,
          });
        }
      });
    }
    if (item.Type === 'Political.Ocr') {
      item.PoliticalOcrTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.ocr_political_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            url: set.Url,
            keyWords: set.KeywordSet,
            coords: set.AreaCoordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.ocr_political_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            url: set.Url,
            keyWords: set.KeywordSet,
            coords: set.AreaCoordSet,
          });
        }
      });
    }
    if (item.Type === 'Political.Asr') {
      item.PoliticalAsrTask?.Output?.SegmentSet?.forEach(set => {
        if (set.Suggestion === 'block') {
          auditTimeline.asr_political_block?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
          });
        }
        if (set.Suggestion === 'review') {
          auditTimeline.asr_political_review?.push({
            startTime: set.StartTimeOffset,
            endTime: set.EndTimeOffset,
            confidence: set.Confidence,
            keyWords: set.KeywordSet,
          });
        }
      });
    }
  });
  return auditTimeline;
}
