/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 片头片尾
 */

import React, { useMemo } from 'react';
import { useStore } from '../dataflow/context';
import EleVideoClip from './EleVideoClip';
import { formatTimePoint } from '../common/util';

const AnalyCreditsTitle = () => {
  const cover = '//facedetectioncos-1251132611.cos.ap-guangzhou.myqcloud.com/default/cov_default.jpg';
  const { analyCreditsTitle, player } = useStore();

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  return useMemo(
    () => (
      <div className="public-video-clip-list">
        {analyCreditsTitle ? (
          <>
            <div onClick={() => updatePlayerTime(analyCreditsTitle.hTime)}>
              <EleVideoClip
                title="片头"
                cover={cover}
                descs={[
                  {
                    label: '结束点',
                    value: formatTimePoint(analyCreditsTitle.hTime),
                  },
                ]}
              />
            </div>
            <div onClick={() => updatePlayerTime(analyCreditsTitle.tTime)}>
              <EleVideoClip
                title="片尾"
                cover={cover}
                descs={[
                  {
                    label: '开始点',
                    value: formatTimePoint(analyCreditsTitle.tTime),
                  },
                ]}
              />
            </div>
          </>
        ) : (
          <p className="public-desc-text">暂无数据</p>
        )}
      </div>
    ),
    [analyCreditsTitle, player],
  );
};

export default React.memo(AnalyCreditsTitle);
