/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 时间轴
 */

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useStore } from '../dataflow/context';
import { formatTimeMss } from '../common/util';
import { ValueOf, StoreType } from '../interface.d';

export const optionMap = {
  porn: [
    { value: 'porn', label: '色情' },
    { value: 'sexy', label: '性感' },
    { value: 'vulgar', label: '低俗' },
    { value: 'intimacy', label: '亲密行为' },
  ],
  terrorism: [
    { value: 'guns', label: '武器枪支' },
    { value: 'crowd', label: '人群聚集' },
    { value: 'police', label: '警察部队' },
    { value: 'bloody', label: '血腥画面' },
    { value: 'banners', label: '涉敏旗帜' },
    { value: 'militant', label: '武装分子' },
    { value: 'explosion', label: '爆炸火灾' },
    { value: 'terrorists', label: '涉敏人物' },
    { value: 'scenario', label: '涉敏画面' },
  ],
  political: [
    { value: 'violation_photo', label: '违规图标' },
    { value: 'entertainment', label: '娱乐人物' },
    { value: 'sport', label: '体育人物' },
    { value: 'politician', label: '政治人物' },
  ],
};

const AuditTimeline = () => {
  const {
    player,
    auditFilterType,
    auditFilterSet,
    auditFilterSuggestion,
    auditTimeline,
    eleVideoPlayer,
    videoPlayTime,
  } = useStore();
  const dispatch = useDispatch();
  const [options, setOptions] = useState(optionMap.porn);
  const [splits, setSplits] = useState<string[]>([]);
  const [fragments, setFragments] = useState<ValueOf<StoreType['auditTimeline']>>([]);
  const refBar = useRef<HTMLHeadingElement>(null);
  const refBarWrap = useRef<HTMLHeadingElement>(null);
  const [isDraging, setIsDraging] = useState(false);
  const [dragingValue, setDragingValue] = useState('0px');

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  const onClickBar = (e: React.MouseEvent) => {
    // adjustX 是调试出来的，应该是鼠标头与触发点的位移偏差
    const adjustX = 9;
    const diffX = e.clientX - (refBar.current?.getBoundingClientRect().x || 0) - adjustX;
    const clientW = refBarWrap.current?.getBoundingClientRect().width || 601;
    const diffTime = (diffX / clientW) * eleVideoPlayer.duration;
    player?.currentTime(videoPlayTime + diffTime);
  };

  function clickDragBar(e: React.MouseEvent) {
    const ele = refBar.current as HTMLElement;
    const lastX = e.clientX;
    const lastLeft = ele.style.left;
    setDragingValue(lastLeft);
    setIsDraging(true);
    let xDiffPercent = 0;
    const test = (e: MouseEvent) => {
      const currentX = e.clientX;
      xDiffPercent = +(((currentX - lastX) / refBarWrap.current?.clientWidth!) * 100).toFixed(2);
      let left = parseInt(lastLeft) + xDiffPercent;
      if (left < 0) {
        left = 0;
      }
      if (left > 100) {
        left = 100;
      }
      ele.style.left = left + '%';
    };
    window.addEventListener('mousemove', test);
    window.onmouseup = () => {
      window.removeEventListener('mousemove', test);
      let curTime = videoPlayTime + (xDiffPercent / 100) * eleVideoPlayer.duration;
      curTime = curTime < 0 ? 0 : curTime;
      player?.currentTime(curTime);
      dispatch('setVideoPlayTime', curTime);
      setIsDraging(false);
      window.onmouseup = null;
    };
  }

  useEffect(() => {
    setOptions(optionMap[auditFilterType]);
  }, [auditFilterType]);

  useEffect(() => {
    const key: keyof StoreType['auditTimeline'] = `${auditFilterSet}_${auditFilterType}_${auditFilterSuggestion}`;
    setFragments(auditTimeline[key] || []);
  }, [auditTimeline, auditFilterType, auditFilterSet, auditFilterSuggestion]);

  useEffect(() => {
    const duration = eleVideoPlayer?.duration || 0;
    if (duration === 0) {
      setSplits([]);
    }
    const per = duration / 10;
    const points = [...Array(11)].map(function (_, num) {
      return formatTimeMss(num * per);
    });
    setSplits(points);
  }, [eleVideoPlayer.duration]);

  return useMemo(
    () => (
      <div className="cva-audit-result-content">
        <div className="name-section">
          <p className="name-item title">名称</p>
          {options.map(option => (
            <p key={option.value} className="name-item">
              {option.label}
            </p>
          ))}
        </div>
        <div className="time-line-section-wrap">
          <div className="time-line-section">
            <div className="time-line-item title" onClick={e => onClickBar(e)}>
              {splits.map((point, index) => (
                <div key={index} className={`per-sixty ${index + 1 === splits.length ? 'per-sixty-end' : ''}`}>
                  <p className="scale-text">{point}</p>
                </div>
              ))}
            </div>
            {options.map(option => (
              <div key={option.value} className="time-line-item">
                <div className="time-line-item-content">
                  {fragments?.map((item, index) => {
                    if (item.subLable ? item.subLable.includes(option.value) : item.label === option.value) {
                      return (
                        <div
                          key={index}
                          className="fragment"
                          style={{
                            left: `${Math.floor((item.startTime / eleVideoPlayer.duration) * 300) / 3}%`,
                            width: `${((item.endTime - item.startTime) / eleVideoPlayer.duration) * 100}%`,
                          }}
                          onClick={() => updatePlayerTime(item.startTime)}
                        ></div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            ))}
            <div ref={refBarWrap} className="drag-bar-wrap">
              <div
                ref={refBar}
                className="drag-bar"
                onMouseDown={clickDragBar}
                style={{
                  left: isDraging
                    ? dragingValue
                    : Math.floor((videoPlayTime / eleVideoPlayer.duration) * 300) / 3 + '%',
                }}
              >
                <div className="btn"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [
      player,
      auditFilterType,
      auditFilterSet,
      auditFilterSuggestion,
      auditTimeline,
      eleVideoPlayer,
      videoPlayTime,
      options,
      splits,
      fragments,
      refBar,
      refBarWrap,
    ],
  );
};

export default React.memo(AuditTimeline);
