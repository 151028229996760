/**
 * @user simxin
 * @desc 请求处理
 */

import { describeCommonDetail, describeUserDetail, describePersonList, describeDemoPersonList } from '@src/common/api';
import { DetailType, StoreType, StringKVObject } from '../interface.d';
import {
  translateEleVideoPlayer,
  translateAnalyMeta,
  translateRecogFace,
  translateRecogFaceId,
  translateRecogObject,
  translateRecogObjectId,
  translateRecogSubtitlePath,
  translateRecogVoiceTotal,
  translateRecogVoiceSnippet,
  translateRecogTextTotal,
  translateRecogTextSnippet,
  translateAnalyTagWithTime,
  translateAnalyTagWithLabel,
  translateAnalyStrip,
  translateAnalyHighlight,
  translateAnalyCreditsTitle,
  translateAnalyCopyright,
  translateAnalyAbstract,
  translateAuditTimeline,
} from './translator';

type DescribeDetailProps = {
  type: DetailType;
  params: AiSession.DetailReq;
};

/**
 * @desc 获取视频任务信息
 */
export async function describeDetail({ type, params }: DescribeDetailProps): Promise<StoreType | null> {
  if (!['example', 'user'].includes(type)) return null;

  try {
    let file: AiSession.CommonFile = {} as AiSession.CommonFile;
    if (type === 'example') [file] = await describeCommonDetail(params);
    if (type === 'user') [file] = await describeUserDetail(params);

    if (!file) return null;

    const { defaultFaceUrls, userDefineFaceUrls } = await describePersons(file, type);

    const eleVideoPlayer: StoreType['eleVideoPlayer'] = translateEleVideoPlayer(file);
    const analyMeta: StoreType['analyMeta'] = translateAnalyMeta(file);

    const recogFace: StoreType['recogFace'] = translateRecogFace(file, defaultFaceUrls, userDefineFaceUrls);
    const recogObject: StoreType['recogObject'] = translateRecogObject(file);
    const recogFaceId: StoreType['recogFaceId'] = translateRecogFaceId(file);
    const recogObjectId: StoreType['recogObjectId'] = translateRecogObjectId(file);
    const recogSubtitlePath: StoreType['recogSubtitlePath'] = translateRecogSubtitlePath(file);
    const recogVoiceTotal: StoreType['recogVoiceTotal'] = translateRecogVoiceTotal(file);
    const recogVoiceSnippet: StoreType['recogVoiceSnippet'] = translateRecogVoiceSnippet(file);
    const recogTextTotal: StoreType['recogTextTotal'] = translateRecogTextTotal(file);
    const recogTextSnippet: StoreType['recogTextSnippet'] = translateRecogTextSnippet(file);

    const analyTagWithTime: StoreType['analyTagWithTime'] = translateAnalyTagWithTime(file);
    const analyTagWithLabel: StoreType['analyTagWithLabel'] = translateAnalyTagWithLabel(file);
    const analyStrip: StoreType['analyStrip'] = translateAnalyStrip(file);
    const analyHighlight: StoreType['analyHighlight'] = translateAnalyHighlight(file);
    const analyCreditsTitle: StoreType['analyCreditsTitle'] = translateAnalyCreditsTitle(file);
    const analyCopyright: StoreType['analyCopyright'] = translateAnalyCopyright(file);
    const analyAbstract: StoreType['analyAbstract'] = translateAnalyAbstract(file);

    const auditTimeline: StoreType['auditTimeline'] = translateAuditTimeline(file);

    return {
      eleVideoPlayer,
      analyMeta,
      recogFace,
      recogFaceId,
      recogObject,
      recogObjectId,
      recogSubtitlePath,
      recogVoiceTotal,
      recogVoiceSnippet,
      recogTextTotal,
      recogTextSnippet,
      analyTagWithTime,
      analyTagWithLabel,
      analyStrip,
      analyHighlight,
      analyCreditsTitle,
      analyCopyright,
      analyAbstract,
      auditTimeline,
    } as StoreType;
  } catch (e) {
    console.log('describe file detail error: ', e);
    return null;
  }
}

/**
 * @desc 获取人脸图片
 */
export async function describePersons(file: AiSession.CommonFile, type: string) {
  const defaultFaceUrls: StringKVObject = {};
  const userDefineFaceUrls: StringKVObject = {};

  try {
    const defaultRecogFaceIds: string[] = [];
    const defaultRecogFaceNames: string[] = [];
    const userDefineRecogFaceIds: string[] = [];

    file.AiRecognitionResultSet?.forEach(item => {
      if (item.Type === 'FaceRecognition') {
        item.FaceTask.Output?.ResultSet?.forEach(set => {
          if (set.Type === 'Default') {
            defaultRecogFaceIds.push(set.Id);
            defaultRecogFaceNames.push(set.Name);
          }
          if (set.Type === 'UserDefine') {
            userDefineRecogFaceIds.push(set.Id);
          }
        });
      }
    });

    const [defaultRet, userDefindRet] = await Promise.all([
      defaultRecogFaceNames.length &&
        describeDemoPersonList({
          Type: 'Default',
          PersonId: defaultRecogFaceIds,
          Names: defaultRecogFaceNames,
        }),
      type === 'user' &&
        describePersonList({
          Type: 'UserDefine',
          PersonId: userDefineRecogFaceIds,
        }),
    ]).catch(e => {
      return [null, null];
    });

    if (defaultRet && defaultRet.PersonSet) {
      defaultRet.PersonSet.forEach(set => {
        set.FaceInfoSet.forEach(face => {
          defaultFaceUrls[set.PersonId] = face.Url;
        });
      });
    }

    if (userDefindRet && userDefindRet.PersonSet) {
      userDefindRet.PersonSet.forEach(set => {
        set.FaceInfoSet.forEach(face => {
          userDefineFaceUrls[set.PersonId] = face.Url;
        });
      });
    }

    return { defaultFaceUrls, userDefineFaceUrls };
  } catch (e) {
    console.log('describe person face error: ', e);
    return { defaultFaceUrls, userDefineFaceUrls };
  }
}
