/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 返回组件
 */

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../dataflow/context';
import { IconFont } from 'tdesign-icons-react';

const typeMap: any = {
  example: '返回示例视频',
  user: '返回我的视频',
};

const EleGoBack = () => {
  const navigat = useNavigate();
  const { detailType } = useStore();

  const goBack = function () {
    navigat(`/list/${detailType === 'example' ? 'example-video' : 'my-video'}`);
  };

  return useMemo(
    () => (
      <div className="cva-back-title">
        <p className="link-back" onClick={goBack}>
          <IconFont name="chevron-left" style={{ fontSize: '24px' }} />
          <span className="text">{typeMap[detailType]}</span>
        </p>
      </div>
    ),
    [detailType],
  );
};

export default React.memo(EleGoBack);
