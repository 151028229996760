/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 集锦
 */

import React, { useState, useMemo } from 'react';
import { Dialog } from 'tdesign-react';
import { VideoDefaultWidth, VideoDefaultHeight } from '../config';
import { useStore } from '../dataflow/context';
import EleVideoClip from './EleVideoClip';
import { formatTimePoint } from '../common/util';

const AnalyHighlight = () => {
  const { analyHighlight, player } = useStore();
  const [playerVisible, setPlayerVisible] = useState(false);
  const [playerUrl, setPlayerUrl] = useState('');

  const playHighlight = (value: string) => {
    player?.pause();
    setPlayerUrl(value);
    setPlayerVisible(true);
  };

  const destoryHighlight = () => {
    player?.play();
    setPlayerUrl('');
    setPlayerVisible(false);
  };

  return useMemo(
    () =>
      analyHighlight.length ? (
        <div className="public-video-clip-list">
          {analyHighlight.map((item, index) => (
            <div key={index} onClick={() => playHighlight(item.url)}>
              <EleVideoClip
                title={`集锦${index + 1}`}
                cover={item.cover}
                descs={[
                  {
                    label: '视频时长',
                    value: formatTimePoint(item.duration),
                  },
                ]}
              />
            </div>
          ))}
          <Dialog
            className="analy-highlight-video-dialog-wrap"
            width="800px"
            visible={playerVisible}
            header={false}
            footer={false}
            onClose={destoryHighlight}
          >
            <div className="analy-highlight-video-wrap">
              <video controls autoPlay width={VideoDefaultWidth} height={VideoDefaultHeight} src={playerUrl} />
            </div>
          </Dialog>
        </div>
      ) : (
        <p className="public-desc-text">暂无数据</p>
      ),
    [analyHighlight, playerVisible, playerUrl, player],
  );
};

export default React.memo(AnalyHighlight);
