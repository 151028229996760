import { useEffect, useState } from "react";

/**
 * 异步处理hook
 */
export function useAsync<T>(
  callback: () => Promise<T>,
  deps: any[]
): [T, () => Promise<T>] {
  // @ts-ignore
  const [result, setResult] = useState<T>(undefined);

  function retry() {
    return callback().then((data: T) => {
      setResult(data);
      return data;
    });
  }
  useEffect(() => {
    retry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
  return [result, retry];
}
