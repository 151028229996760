/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 图像识别详情
 */

import React, { useState, useRef, useMemo } from 'react';
import { IconFont } from 'tdesign-icons-react';
import { useStore } from '../dataflow/context';
import { formatTimePoint } from '../common/util';
import EleCatchPic from './EleCatchPic';
import { RecogDetailCtx } from '../interface.d';

type EleGraphCardProps = {
  info: RecogDetailCtx;
};

const EleGraphCard = ({ info }: EleGraphCardProps) => {
  const { id, name, url, segments } = info;
  const { eleVideoPlayer, player, videoPlayTime, sessionTab } = useStore();
  const [expand, setExpand] = useState(true);
  const refGraph = useRef<HTMLHeadingElement>(null);

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  const checkTimeRange = (
    ref: React.RefObject<HTMLHeadingElement>,
    classname: string,
    index: number,
    start: number,
    end: number,
  ) => {
    if (start === end) {
      end += 0.1;
    }

    if (start <= videoPlayTime && end > videoPlayTime) {
      const parentDom = ref.current;
      const childDoms = parentDom?.getElementsByClassName(classname) as HTMLCollectionOf<HTMLElement>;
      if (parentDom && childDoms && childDoms[index]) {
        parentDom.scrollTo({
          top: childDoms[index]?.offsetTop - parentDom.clientHeight / 3 || 0,
          behavior: 'smooth',
        });
      }
      return true;
    } else {
      return false;
    }
  };

  return useMemo(
    () => (
      <div className="cva-object-detail" key={id}>
        <div className="object">
          <EleCatchPic className="avatar" src={url} alt="" />
          <div className="detail-info">
            <p className="name">{name}</p>
            <p className="position-title">{sessionTab === 'recog_object' ? '物体出现的位置' : '人物出现的位置'}</p>
            <div className="position-wrap">
              {segments.map((item, index) => (
                <div
                  key={index}
                  className="slider-bar"
                  style={{
                    left: `${(item.startTime / eleVideoPlayer.duration) * 100}%`,
                    width: `${((item.endTime - item.startTime) / eleVideoPlayer.duration) * 100}%`,
                  }}
                  onClick={() => updatePlayerTime(item.startTime)}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className="position-detail-info">
          <div ref={refGraph} className={`position-info-list ${expand ? '' : 'hide'}`}>
            {segments.map((item, index) => (
              <p
                key={`${id}-${index}`}
                className={`ele-graph-card-ref position-info-item ${
                  checkTimeRange(refGraph, 'ele-graph-card-ref', index, item.startTime, item.endTime) ? ' selected' : ''
                }`}
                onClick={() => updatePlayerTime(item.startTime)}
              >
                <span className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</span>
                <span className="text">{`相似度 ${item.confidence}%`}</span>
              </p>
            ))}
          </div>
          <div className="btn" onClick={() => setExpand(!expand)}>
            <span className="text">{expand ? '隐藏具体详情' : '显示具体详情'}</span>
            <IconFont name={expand ? 'chevron-up' : 'chevron-down'} style={{ color: '#006eff' }} />
          </div>
        </div>
      </div>
    ),
    [eleVideoPlayer, player, videoPlayTime, expand, refGraph, info, sessionTab],
  );
};

export default React.memo(EleGraphCard);
