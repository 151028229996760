/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 任务详情
 */

import React, { useEffect, useState, useMemo } from 'react';
import { Tabs } from 'tdesign-react';

import { useDispatch } from '../dataflow/context';
import EleGoBack from '../components/EleGoBack';
import EleVideoPlayer from '../components/EleVideoPlayer';
import EleVideoBound from '../components/EleVideoBound';
import AuditTimeline from '../components/AuditTimeline';
import AnalyMeta from '../components/AnalyMeta';
import ModuleRecog from '../components/ModuleRecog';
import ModuleAnaly from '../components/ModuleAnaly';
import ModuleAudit from '../components/ModuleAudit';
import { StoreType, DetailTab } from '../interface';

const Detail = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<DetailTab>('recog');

  useEffect(() => {
    const sessionTab: StoreType['sessionTab'] =
      activeTab === 'recog'
        ? 'recog_face'
        : activeTab === 'analy'
        ? 'analy_tag_smart'
        : activeTab === 'audit'
        ? 'audit_img_porn_block'
        : 'recog_face';
    dispatch('setSessionTab', sessionTab);
  }, [activeTab]);

  return useMemo(
    () => (
      <div className="cva-body-content">
        <div className="cva-content-wrap">
          <div className="cva-video-detail">
            <EleGoBack />
            <div className="content-wrap">
              <div className="aside-left">
                <div className="video-wrap">
                  <EleVideoPlayer />
                  <EleVideoBound />
                </div>
                {['audit'].includes(activeTab) ? (
                  <div className="result-content">
                    <AuditTimeline />
                  </div>
                ) : null}
                {['recog', 'analy'].includes(activeTab) ? (
                  <div className="result-content">
                    <AnalyMeta />
                  </div>
                ) : null}
              </div>
              <div className="aside-right">
                <Tabs
                  value={activeTab}
                  onChange={value => setActiveTab(value as DetailTab)}
                  style={{ background: 'transparent' }}
                >
                  <Tabs.TabPanel value="recog" label={'智能识别'}>
                    <ModuleRecog />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel value={'analy'} label={'智能分析'}>
                    <ModuleAnaly />
                  </Tabs.TabPanel>
                  <Tabs.TabPanel value={'audit'} label={'智能审核'}>
                    <ModuleAudit />
                  </Tabs.TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    [activeTab],
  );
};

export default React.memo(Detail);
