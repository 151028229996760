/**
 * @user simxin
 * @desc 功能未启用提示
 */

import React, { useMemo } from 'react';
import { DisableIntro } from '../config';

const EleDisableTabIntro = () => {
  return useMemo(() => <div className="cva-disable-tab-desc">{DisableIntro}</div>, []);
};

export default React.memo(EleDisableTabIntro);
