/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 带统计数量的按钮
 */

import React, { useMemo } from 'react';

type EleStatButtonProps = {
  selected?: boolean;
  text: string;
  count: number;
  onSelect: () => void;
};

const EleStatButton = ({ selected = false, text, count, onSelect }: EleStatButtonProps) => {
  return useMemo(
    () => (
      <div className={`cva-checkbox-rect ${selected ? 'selected' : ''}`} onClick={onSelect}>
        <p className="text">{`${text}（${count}）`}</p>
      </div>
    ),
    [selected, text, count],
  );
};

export default React.memo(EleStatButton);
