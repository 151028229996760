/**
 * @user simxin
 * @desc 详情全局数据
 */

import { StoreType } from '../interface.d';

const state: StoreType = {
  detailType: 'example',
  fileId: '',

  sessionTab: 'recog_face',

  player: null,
  videoPlayTime: 0,

  eleVideoPlayer: {
    url: '',
    duration: 0,
    width: 1920,
    height: 1080,
  },

  analyMeta: {
    classes: [],
    tags: [],
    covers: [],
  },

  recogFaceId: '',
  recogFace: {
    status: '',
    recogDetailCtx: [],
  },

  recogObjectId: '',
  recogObject: {
    status: '',
    recogDetailCtx: [],
  },

  recogSubtitleSwitch: false,
  recogSubtitlePath: '',

  recogVoiceTotal: [],
  recogVoiceSnippet: [],

  recogTextTotal: [],
  recogTextSnippet: [],

  analyTagWithTime: [],
  analyTagWithLabel: [],

  analyStrip: [],
  analyHighlight: [],
  analyCreditsTitle: null,
  analyCopyright: '',
  analyAbstract: [],

  auditTimeline: {},
  auditFilterType: 'porn',
  auditFilterSet: 'img',
  auditFilterSuggestion: 'block',
};

export default state;
