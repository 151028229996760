/**
 * @user simxin
 * @desc 视频剪辑片段
 */

import React, { useMemo } from 'react';
import EleCatchPic from './EleCatchPic';

type EleVideoClipPrrops = {
  title: string;
  cover?: string;
  onClick?: () => any;
  select?: boolean;
  descs: { label: string; value: string }[];
};

const EleVideoClip = ({ title, cover, descs, onClick, select = false }: EleVideoClipPrrops) => {
  const classNames = `cva-video-clip ${select ? 'is-select' : ''} ${onClick ? 'has-hover' : ''}`;
  return useMemo(
    () => (
      <div className={classNames} onClick={onClick}>
        {cover && <EleCatchPic className="avatar" src={cover} alt="" />}
        <div className="info">
          <p className="title">{title}</p>
          {descs.map((item, index) => (
            <p key={index} className="desc">
              <span className="name">{item.label}</span>
              <span className="time">{item.value}</span>
            </p>
          ))}
        </div>
      </div>
    ),
    [classNames, onClick, cover, title, descs],
  );
};

export default React.memo(EleVideoClip);
