/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 文本识别
 */

import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { useStore, useDispatch } from '../dataflow/context';
import { Radio, Tag } from 'tdesign-react';
import { formatTimePoint } from '../common/util';
import { StoreType, RecogTextType } from '../interface.d';

const RecogText = () => {
  const dispatch = useDispatch();
  const { player, recogTextTotal, recogTextSnippet, videoPlayTime } = useStore();
  const [activeWord, setActiveWord] = useState<string>('');
  const [activeTab, setActiveTab] = useState<RecogTextType>('total');
  const refTotal = useRef<HTMLHeadingElement>(null);
  const refSnippet = useRef<HTMLHeadingElement>(null);

  const onChangeWord = (value: string) => {
    setActiveWord(value);
  };

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  const checkTimeRange = (
    ref: React.RefObject<HTMLHeadingElement>,
    classname: string,
    index: number,
    start: number,
    end: number,
  ) => {
    if (start === end) {
      end += 0.1;
    }

    if (start <= videoPlayTime && end > videoPlayTime) {
      const parentDom = ref.current;
      const childDoms = parentDom?.getElementsByClassName(classname) as HTMLCollectionOf<HTMLElement>;
      if (parentDom && childDoms && childDoms[index]) {
        parentDom.scrollTo({
          top: childDoms[index]?.offsetTop - parentDom.clientHeight / 3 || 0,
          behavior: 'smooth',
        });
      }
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const sessionTab: StoreType['sessionTab'] =
      activeTab === 'total' ? 'recog_text_total' : activeTab === 'snippet' ? 'recog_text_snippet' : 'recog_text_total';
    dispatch('setSessionTab', sessionTab);
  }, [activeTab]);

  useEffect(() => {
    if (recogTextSnippet.length) {
      setActiveWord(recogTextSnippet[0].word);
    } else {
      setActiveWord('');
    }
  }, [recogTextSnippet]);

  return useMemo(
    () => (
      <Fragment>
        <Radio.Group value={activeTab} onChange={value => setActiveTab(value as RecogTextType)}>
          <Radio value="total">片段识别</Radio>
          <Radio value="snippet">关键词识别</Radio>
        </Radio.Group>
        {activeTab === 'total' ? (
          recogTextTotal.length ? (
            <div ref={refTotal} className="public-text-content-wrap">
              {recogTextTotal.map((item, index) => (
                <div
                  key={index}
                  className={`recog-text-total-ref text-item ${
                    checkTimeRange(refTotal, 'recog-text-total-ref', index, item.startTime, item.endTime)
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => updatePlayerTime(item.startTime)}
                >
                  <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                  <p className="text">{item.texts.reduce((s, c) => `${s ? s + ', ' : s} ${c.text}`, '')}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
        {activeTab === 'snippet' ? (
          recogTextSnippet.length ? (
            <>
              <div className="public-tag-wrap">
                {recogTextSnippet.map((item, index) => (
                  <Tag.CheckTag key={index} checked={item.word === activeWord} onClick={() => onChangeWord(item.word)}>
                    {item.word}
                  </Tag.CheckTag>
                ))}
              </div>
              <div ref={refSnippet} className="public-text-content-wrap">
                {recogTextSnippet
                  .find(item => item.word === activeWord)
                  ?.segments.map((item, index) => (
                    <div
                      key={index}
                      className={`recog-text-snippet-ref text-item text-item__similarity ${
                        checkTimeRange(refSnippet, 'recog-text-snippet-ref', index, item.startTime, item.endTime)
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => updatePlayerTime(item.startTime)}
                    >
                      <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                      <p className="text">{`相似度 ${item.confidence}%`}</p>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
      </Fragment>
    ),
    [player, recogTextTotal, recogTextSnippet, videoPlayTime, activeWord, activeTab, refTotal, refSnippet],
  );
};

export default React.memo(RecogText);
