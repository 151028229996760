import React from 'react';
import objectJpg from '../assets/figure.jpg';

const ObjectCard = (props: { selected?: boolean; imgType?: 'cover' | 'contain'; object: AiSamplePerson }) => {
  const { selected, imgType = 'cover' } = props;
  const img = props.object.FaceInfoSet?.[0]?.Url;

  return (
    <div className={`cva-object-card ${selected ? 'selected' : ''}`}>
      <img src={img || objectJpg} style={{ objectFit: imgType }} alt="" />
      <p className="name">{props.object.Name}</p>
    </div>
  );
};

export default React.memo(ObjectCard);
