import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'whatwg-fetch';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import 'tdesign-react/es/style/index.css';
import './style/reset.css';
import './style/style.css';
import './style/own.css';
import List from './pages/list/list';
import Detail from './pages/detail';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HashRouter basename="/">
      <Routes>
        <Route path="" element={<Home />}></Route>
        <Route path="list/*" element={<List />}></Route>
        <Route path="detail" element={<Detail />}></Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
