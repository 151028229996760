/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 播放器浮层框
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { VideoDefaultWidth, VideoDefaultHeight } from '../config';
import { useStore } from '../dataflow/context';
import { AuditTimelineItem, Coords, StoreType } from '../interface';

const EleVideoBound = () => {
  const {
    sessionTab,
    auditTimeline,
    eleVideoPlayer,
    videoPlayTime,
    recogFace,
    recogFaceId,
    recogObject,
    recogObjectId,
  } = useStore();
  const [bounds, setBounds] = useState<Coords[]>([[12, 12, 12, 12]]);
  let [wrapWidth, setWrapWidth] = useState(VideoDefaultWidth);
  let [wrapHeight, setWrapHeight] = useState(VideoDefaultHeight);
  const [videoWidth, setVideoWidth] = useState(1920);
  const [videoHeight, setVideoHeight] = useState(1080);
  const refBoundsWrap = useRef<HTMLHeadingElement>(null);

  const convertCoord = (coords: Coords): Coords => {
    const [x1, y1, x2, y2] = coords;
    return [
      (x1 / videoWidth) * wrapWidth,
      (y1 / videoHeight) * wrapHeight,
      (x2 / videoWidth) * wrapWidth,
      (y2 / videoHeight) * wrapHeight,
    ];
  };

  useEffect(() => {
    const video = document.querySelector('video')!;
    if (!video) return;
    video.onloadedmetadata = () => {
      const realWidth = video.videoWidth;
      const realHeight = video.videoHeight;
      setVideoWidth(realWidth);
      setVideoHeight(realHeight);
      // 根据原始分辨率重新计算wrap宽高
      if (eleVideoPlayer.width !== 0) {
        if (wrapWidth / wrapHeight < realWidth / realHeight) {
          wrapHeight = wrapWidth / (realWidth / realHeight);
        } else {
          wrapWidth = (realWidth / realHeight) * wrapHeight;
        }
        setWrapWidth(wrapWidth || VideoDefaultWidth);
        setWrapHeight(wrapHeight || VideoDefaultHeight);
      }
    };
  }, []);

  // useEffect(() => {
  //   // player 导出的width不准
  //   const realWidth = eleVideoPlayer.width;
  //   const realHeight = eleVideoPlayer.height;
  //   setVideoWidth(realWidth);
  //   setVideoHeight(realHeight);
  //   if (eleVideoPlayer.width !== 0) {
  //     if (wrapWidth / wrapHeight < realWidth / realHeight) {
  //       wrapHeight = wrapWidth / (realWidth / realHeight);
  //     } else {
  //       wrapWidth = (realWidth / realHeight) * wrapHeight;
  //     }
  //     setWrapWidth(wrapWidth || VideoDefaultWidth);
  //     setWrapHeight(wrapHeight || VideoDefaultHeight);
  //   }
  // }, [eleVideoPlayer.width, eleVideoPlayer.height]);

  useEffect(() => {
    const bounds: Coords[] = [];

    if (sessionTab === 'recog_face') {
      const face = recogFace.recogDetailCtx.find(item => item.id === recogFaceId);
      if (face && face.segments) {
        face.segments?.forEach(item => {
          if (item.startTime === item.endTime) {
            item.endTime += 0.1;
          }
          if (videoPlayTime >= item.startTime && videoPlayTime < item.endTime) {
            bounds.push(convertCoord(item.coords));
          }
        });
      }
    }
    if (sessionTab === 'recog_object') {
      const obj = recogObject.recogDetailCtx.find(item => item.id === recogObjectId);
      if (obj && obj.segments) {
        obj.segments?.forEach(item => {
          if (item.startTime === item.endTime) {
            item.endTime += 0.1;
          }
          if (videoPlayTime >= item.startTime && videoPlayTime < item.endTime) {
            bounds.push(convertCoord(item.coords));
          }
        });
      }
    }
    if (sessionTab.includes('audit_')) {
      const key = sessionTab.replace('audit_', '') as keyof StoreType['auditTimeline'];
      const list: AuditTimelineItem<any>[] = auditTimeline[key] || [];
      if (!list?.length) return;
      for (let item of list) {
        if (item.endTime - item.startTime < 0.5) {
          item.startTime = +(item.startTime - 0.25).toFixed(3);
          item.endTime = +(item.endTime + 0.25).toFixed(3);
        }
        if (!item?.coords?.length) continue;
        if (videoPlayTime >= item.startTime && videoPlayTime < item.endTime) {
          bounds.push(convertCoord(item.coords!));
        }
      }
    }
    setBounds(bounds);
  }, [videoPlayTime, sessionTab]);

  return useMemo(
    () => (
      <div ref={refBoundsWrap} className="video-bound-wrap" style={{ width: wrapWidth, height: wrapHeight }}>
        {bounds.map(([x1, y1, x2, y2], index) => (
          <i
            key={index}
            className="bound-item"
            style={{
              width: `${x2 - x1}px`,
              height: `${y2 - y1}px`,
              left: `${x1}px`,
              top: `${y1}px`,
            }}
          />
        ))}
      </div>
    ),
    [
      sessionTab,
      eleVideoPlayer,
      videoPlayTime,
      recogFace,
      recogFaceId,
      recogObject,
      recogObjectId,
      bounds,
      wrapWidth,
      wrapHeight,
      videoWidth,
      videoHeight,
      refBoundsWrap,
    ],
  );
};

export default React.memo(EleVideoBound);
