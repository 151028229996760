/**
 * @user simxin
 * @desc 配置项
 */

import { SessionTab } from '../interface.d';

export const DisableIntro = '该类型的视频分析结果稍后提供，请及时关注我们的体验页面，谢谢。';
export const DisableTab: SessionTab[] = ['analy_copyright'];
export const CatchPic = '//facedetectioncos-1251132611.cos.ap-guangzhou.myqcloud.com/default/cov_default.jpg';
export const VideoDefaultWidth = 709;
export const VideoDefaultHeight = 398;
