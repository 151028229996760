import {
  Tabs,
  Button,
  Tag,
  Input,
  Dialog,
  Upload,
  DialogPlugin,
  Textarea,
  InputAdornment,
  UploadFile,
  message,
  Checkbox,
} from 'tdesign-react';
import { IconFont } from 'tdesign-icons-react';
import ButtonAdd from '../../components/ButtonAdd';
import ObjectCard from '../../components/ObjectCard';
import React, { useState } from 'react';
import { useAsync } from '../../components/common-hook';
import {
  createWordSamples,
  createPersonSample,
  deletePersonSample,
  describePersonList,
  describeWordSamples,
  deleteWordSamples,
  modifyPersonSample,
  modifyWordSamples,
} from '../../common/api';
import { blobToBase64 } from '@src/util/tools';

const { TabPanel } = Tabs;
const defaultPerson: UploadFile = {
  url: 'https://cloudcache.tencent-cloud.com/qcloud/ui/static/static_source_business/839919ab-95af-492e-87e6-57d566e0edfc.jpg',
  name: 'default.jpeg',
  status: 'success',
};
const wordUsage: WordUsage[] = ['Recognition.Ocr', 'Review.Ocr'];
const audioUsage: WordUsage[] = ['Recognition.Asr', 'Review.Asr'];

function LibraryTab() {
  const publicPerson = !!+(localStorage.getItem('ai-library-public-person') || '1');
  const [isShowAddFigureDialog, setIsShowAddFigureDialog] = useState<boolean>(false);
  const [word, setWord] = useState('');
  const [wordType, setWordType] = useState('text');
  const [isUsePublic, setIsUsePublic] = useState(publicPerson);
  const [addVisible, setAddPopupVisible] = useState(false);
  const [personNameSearch, setPersonNameSearch] = useState('');
  const [textSearch, setTextNameSearch] = useState('');
  const [audioSearch, setAudioSearch] = useState('');
  const [personName, setPersonName] = useState('');
  const [uploadList, setUploadList] = useState<UploadFile[]>([defaultPerson]);
  const [editPersonData, setEditPersonData] = useState<AiSamplePerson>();
  const [personUserList = [], reGetPersonUserList] = useAsync(
    () =>
      describePersonList({ Type: 'UserDefine', Limit: 100, Names: personNameSearch ? [personNameSearch] : [] }).then(
        ({ PersonSet }) => PersonSet,
      ),
    [],
  );
  const [wordList = [], regetWordList] = useAsync(
    () =>
      describeWordSamples({ Usages: ['Recognition.Ocr', 'Review.Ocr'], Keywords: textSearch ? [textSearch] : [] }).then(
        data => data?.WordSet,
      ),
    [],
  );
  const [audioList = [], regetAudioList] = useAsync(
    () =>
      describeWordSamples({
        Usages: ['Recognition.Asr', 'Review.Asr'],
        Keywords: audioSearch ? [audioSearch] : [],
      }).then(data => data?.WordSet),
    [],
  );
  function addPerson() {
    setPersonName('');
    setUploadList([defaultPerson]);
    setEditPersonData(undefined);
    setIsShowAddFigureDialog(true);
  }
  function createPerson() {
    const realList = uploadList.filter((_, i) => i !== 0);
    const newList = realList.filter(item => item.raw);
    const delList = editPersonData?.FaceInfoSet?.filter(({ Url }) => !realList.find(item => item.url === Url));
    if (!personName || !realList.length) {
      message.error('请先完善人物信息以及选择要上传的照片');
      return;
    }
    if (realList.length > 20) {
      message.error('照片总数超过限制');
      return;
    }
    if (newList.length > 5) {
      message.error('单次提交照片数量超过限制');
      return;
    }
    const urlList = newList.map(({ url }) => url.replace(/^data:image.*;base64,/, ''));
    (editPersonData
      ? Promise.all([
          newList.length &&
            modifyPersonSample({
              PersonId: editPersonData.PersonId || '',
              FaceOperationInfo: {
                Type: 'add',
                FaceContents: urlList,
              },
            }),
          delList?.length &&
            modifyPersonSample({
              PersonId: editPersonData.PersonId || '',
              FaceOperationInfo: {
                Type: 'delete',
                FaceIds: delList?.map(item => item.FaceId) || [],
              },
            }),
        ])
      : createPersonSample({
          Name: personName,
          Usages: ['All'],
          FaceContents: urlList,
        })
    )
      .then(() => {
        reGetPersonUserList();
        setIsShowAddFigureDialog(false);
      })
      .catch(() => {
        message('error', '图片格式错误，请更换图片后重试');
      });
  }
  function startEditPerson(data: AiSamplePerson) {
    const list: UploadFile[] = data.FaceInfoSet?.map((item, i) => {
      return {
        url: item.Url,
        status: 'success',
        name: String(i),
        percent: 100,
      };
    });
    setPersonName(data.Name);
    setUploadList([defaultPerson, ...list]);
    setEditPersonData(data);
    setIsShowAddFigureDialog(true);
  }
  function deletePerson(data: AiSamplePerson) {
    const mydialog = DialogPlugin({
      header: '删除人物',
      body: `确认删除人物 ${data.Name} 吗？删除后数据将无法恢复。`,
      onConfirm: () => {
        deletePersonSample({ PersonId: data.PersonId }).then(() => {
          reGetPersonUserList();
          mydialog.hide();
        });
      },
      onClose: () => {
        mydialog.hide();
      },
    });
  }
  function deleteText(data: AiSampleWord) {
    const mydialog = DialogPlugin({
      header: '删除文本关键词',
      body: `确认文本关键词 ${data.Keyword} 吗？删除后数据将无法恢复。`,
      onConfirm: () => {
        const otherUsage = data.UsageSet?.filter(usage => !wordUsage.includes(usage));
        (otherUsage?.length === 0
          ? deleteWordSamples({ Keywords: [data.Keyword] })
          : modifyWordSamples({
              Keyword: data.Keyword,
              Usages: otherUsage,
            })
        ).then(() => {
          regetWordList();
          regetAudioList();
          mydialog.hide();
        });
      },
      onClose: () => {
        mydialog.hide();
      },
    });
  }
  function deleteAudio(data: AiSampleWord) {
    const mydialog = DialogPlugin({
      header: '删除语音关键词',
      body: `确认语音关键词 ${data.Keyword} 吗？删除后数据将无法恢复。`,
      onConfirm: () => {
        const otherUsage = data.UsageSet?.filter(usage => !audioUsage.includes(usage));
        (otherUsage?.length === 0
          ? deleteWordSamples({ Keywords: [data.Keyword] })
          : modifyWordSamples({
              Keyword: data.Keyword,
              Usages: otherUsage,
            })
        ).then(() => {
          regetWordList();
          regetAudioList();
          mydialog.hide();
        });
      },
      onClose: () => {
        mydialog.hide();
      },
    });
  }
  function addWords() {
    const words = word.split(/[\n\r]/);
    if (!words.length) {
      message.error(' 请输入有效的关键词');
      return;
    }
    createWordSamples({
      Usages: wordType === 'text' ? ['Recognition.Ocr', 'Review.Ocr'] : ['Recognition.Asr', 'Review.Asr'],
      Words: words.map(key => ({ Keyword: key })),
    })
      .then(() => {
        regetWordList();
        regetAudioList();
        setAddPopupVisible(false);
      })
      .catch(() => {
        message('error', '输入长度超限，最长支持输入20个文字');
      });
  }
  return (
    <div className="cva-home-my-ai-library">
      <Dialog
        className="cva-add-figure-dialog"
        visible={isShowAddFigureDialog}
        header={editPersonData ? '修改自定义人物' : '添加自定义人物'}
        width="800px"
        onConfirm={createPerson}
        onClose={() => setIsShowAddFigureDialog(false)}
      >
        <>
          <div className="section-column">
            <p className="label">人物名称</p>
            <div className="form-section">
              <div className="name-input-wrap">
                <Input
                  type="text"
                  placeholder="请输入人物名称"
                  value={personName}
                  onChange={(v: any) => setPersonName(v)}
                />
              </div>
            </div>
          </div>
          <div className="section-column">
            <p className="label" style={{ alignSelf: 'baseline' }}>
              人物图片
            </p>
            <div className="form-section">
              <Upload
                max={21}
                sizeLimit={{
                  size: 1.5,
                  unit: 'MB',
                  message: '图片大小不超过 1.5 MB',
                }}
                theme="image"
                accept=".jpg, .jpeg"
                autoUpload
                multiple
                files={uploadList}
                onSelectChange={data => {
                  const cur = data[data.length - 1];
                  if (cur.size! > 1.5 * 1024 * 1024) {
                    message.error(`${cur.name}：文件超过 1.5 MB`);
                    return;
                  }
                  setUploadList(list => [...list, cur]);
                  blobToBase64(cur.raw!).then((url: string) => {
                    cur.url = url;
                    cur.percent = 100;
                    cur.status = 'success';
                    setUploadList(list => {
                      list.pop();
                      list.push(cur);
                      return [...list];
                    });
                  });
                }}
                onRemove={({ index }) => setUploadList(list => list.filter((_, i) => i !== index))}
              />
              <div className="tips-section">
                <p className="text">*请上传正面人脸清晰的照片</p>
                <p className="text">*单次提交不超过 5 张, 总共不超过 20 张</p>
                <p className="text">*格式仅支持jpg，大小不超过 1.5 MB，分辨率不低于200*200</p>
                <p className="text">*上传成功后一分钟以后支持检索</p>
                <p className="text">*上传的人物示例如果差异太大，将直接影响识别结果</p>
              </div>
            </div>
          </div>
        </>
      </Dialog>
      <Dialog
        visible={addVisible}
        onClose={() => setAddPopupVisible(false)}
        onConfirm={addWords}
        header={wordType === 'text' ? '添加文本识别关键词' : '添加语音识别关键词'}
      >
        <>
          可以批量添加多个关键词，换行输入，一行一个
          <Textarea className="word-input" onChange={setWord} autosize={{ minRows: 1, maxRows: 30 }}></Textarea>
        </>
      </Dialog>
      <Tabs defaultValue="analyze" style={{ background: 'transparent' }}>
        <TabPanel value="analyze" label="智能识别自定义">
          <div className="tab-panel-content">
            <div className="public-section">
              <p className="title">自定义人物库</p>
              <div className="operate-section">
                {/* <div className="input-wrap"> */}
                <Checkbox
                  checked={isUsePublic}
                  onChange={v => {
                    setIsUsePublic(v);
                    localStorage?.setItem?.('ai-library-public-person', v ? '1' : '0');
                  }}
                >
                  使用公共人物（娱乐、体育、政治等）
                </Checkbox>
                {/* </div> */}
                <ButtonAdd onClick={addPerson}>添加自定义人物</ButtonAdd>
                <div className="figure-list">
                  {personUserList.map(item => (
                    <div className="card-wrap" onClick={() => startEditPerson(item)} key={item.PersonId}>
                      <ObjectCard imgType="contain" object={item} />
                      <div className="delete-btn">
                        <Button
                          shape="circle"
                          size="small"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            deletePerson(item);
                          }}
                        >
                          <IconFont name="delete" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="search-wrap">
                  <InputAdornment
                    append={
                      <div className="search-icon">
                        <IconFont name="search" onClick={reGetPersonUserList} />
                      </div>
                    }
                  >
                    <Input
                      value={personNameSearch}
                      onChange={(v: any) => setPersonNameSearch(v)}
                      type="search"
                      placeholder="请输入要搜索的内容"
                      clearable
                    />
                  </InputAdornment>
                </div>
              </div>
            </div>
            <div className="public-section">
              <p className="title">自定义文本关键词</p>
              <div className="operate-section">
                <ButtonAdd
                  onClick={() => {
                    setWordType('text');
                    setAddPopupVisible(true);
                  }}
                >
                  添加文本识别关键词
                </ButtonAdd>
                <div className="tag-list">
                  {wordList.map(item => (
                    <Tag closable onClose={() => deleteText(item)} key={item.Keyword}>
                      {item.Keyword}
                    </Tag>
                  ))}
                </div>
                <div className="search-wrap">
                  <InputAdornment
                    append={
                      <div className="search-icon">
                        <IconFont name="search" onClick={regetWordList} />
                      </div>
                    }
                  >
                    <Input
                      value={textSearch}
                      onChange={(v: any) => setTextNameSearch(v)}
                      type="search"
                      placeholder="请输入要搜索的内容"
                      clearable
                    />
                  </InputAdornment>
                </div>
              </div>
            </div>
            <div className="public-section">
              <p className="title">自定义语音关键词</p>
              <div className="operate-section">
                <ButtonAdd
                  onClick={() => {
                    setWordType('audio');
                    setAddPopupVisible(true);
                  }}
                >
                  添加语音识别关键词
                </ButtonAdd>
                <div className="tag-list">
                  {audioList.map(item => (
                    <Tag closable onClose={() => deleteAudio(item)} key={item.Keyword}>
                      {item.Keyword}
                    </Tag>
                  ))}
                </div>
                <div className="search-wrap">
                  <InputAdornment
                    append={
                      <div className="search-icon">
                        <IconFont name="search" onClick={regetAudioList} />
                      </div>
                    }
                  >
                    <Input
                      value={audioSearch}
                      onChange={(v: any) => setAudioSearch(v)}
                      type="search"
                      placeholder="请输入要搜索的内容"
                      clearable
                    />
                  </InputAdornment>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        {/* <TabPanel value="audit" label="智能审核自定义">
          <div className="tab-panel-content audit-customize">
            <Button className="btn" theme="default" disabled>
              添加审核模板
            </Button>
            <p className="text">
              视频 AI 体验馆 仅作展示，默认预置模板包含所有审核配置项，用户可以在视频审核页面进行配置项的选择和配置。
            </p>
            <Checkbox className="checkbox" checked disabled>
              默认预设模板
            </Checkbox>
          </div>
        </TabPanel> */}
      </Tabs>
    </div>
  );
}

export default React.memo(LibraryTab);
