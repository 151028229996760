/**
 * @user simxin
 * @desc 分析模块
 */

import React, { useState, useMemo } from 'react';
import { Radio } from 'tdesign-react';
import AnalyTag from './AnalyTag';
import AnalyStrip from './AnalyStrip';
import AnalyHighlight from './AnalyHighlight';
import AnalyCreditsTitle from './AnalyCreditsTitle';
import AnalyCopyright from './AnalyCopyright';
import AnalyAbstract from './AnalyAbstract';
import { ModuleAnalyTab } from '../interface.d';

const ModuleAnaly = () => {
  const [activeTab, setActiveTab] = useState<ModuleAnalyTab>('tag');

  return useMemo(
    () => (
      <div className="tab-panel-wrap">
        <Radio.Group value={activeTab} onChange={value => setActiveTab(value as ModuleAnalyTab)}>
          <Radio.Button value="tag">标签</Radio.Button>
          <Radio.Button value="strip">拆条</Radio.Button>
          <Radio.Button value="highlight">集锦</Radio.Button>
          <Radio.Button value="creditstitle">片头片尾</Radio.Button>
          <Radio.Button value="abstract">视频摘要</Radio.Button>
          {/* <Radio.Button value="copyright">版权识别</Radio.Button> */}
        </Radio.Group>
        {activeTab === 'tag' && (
          <div className="second-tab-content">
            <AnalyTag />
          </div>
        )}
        {activeTab === 'strip' && (
          <div className="second-tab-content">
            <AnalyStrip />
          </div>
        )}
        {activeTab === 'highlight' && (
          <div className="second-tab-content">
            <AnalyHighlight />
          </div>
        )}
        {activeTab === 'creditstitle' && (
          <div className="second-tab-content">
            <AnalyCreditsTitle />
          </div>
        )}
        {activeTab === 'copyright' && (
          <div className="second-tab-content">
            <AnalyCopyright />
          </div>
        )}
        {activeTab === 'abstract' && (
          <div className="second-tab-content">
            <AnalyAbstract />
          </div>
        )}
      </div>
    ),
    [activeTab],
  );
};

export default React.memo(ModuleAnaly);
