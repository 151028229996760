/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 审核筛选
 */

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useStore } from '../dataflow/context';
import EleStatButton from './EleStatButton';
import { StoreType } from '../interface.d';
import AuditList from './AuditList';

const AuditFilter = () => {
  const dispatch = useDispatch();
  const { auditTimeline, auditFilterSet, auditFilterType, auditFilterSuggestion } = useStore();

  const [sets, setSets] = useState([
    { value: 'img', label: '图像审核', count: 0 },
    { value: 'ocr', label: '字幕审核', count: 0 },
    { value: 'asr', label: '语音审核', count: 0 },
  ]);

  const [types, setTypes] = useState([
    { value: 'porn', label: '涉黄', count: 0 },
    { value: 'terrorism', label: '涉恐', count: 0 },
    { value: 'political', label: '涉政', count: 0 },
  ]);

  const [suggestions, setSuggestions] = useState([
    { value: 'block', label: '确认结果', count: 0 },
    { value: 'review', label: '疑似结果', count: 0 },
  ]);

  const onSelectSet = (value: string) => {
    dispatch('setAuditFilterSet', value);
  };

  const onSelectType = (value: string) => {
    dispatch('setAuditFilterType', value);
  };

  const onSelectSuggestion = (value: string) => {
    dispatch('setAuditFilterSuggestion', value);
  };

  useEffect(() => {
    const set = auditFilterSet || 'img';
    const type = auditFilterType || 'porn';
    const suggestion = auditFilterSuggestion || 'block';
    const sessionTab: StoreType['sessionTab'] = `audit_${set}_${type}_${suggestion}`;
    dispatch('setSessionTab', sessionTab);
  }, [auditFilterSet, auditFilterType, auditFilterSuggestion]);

  useEffect(() => {
    const {
      img_porn_block = [],
      img_porn_review = [],
      img_political_block = [],
      img_political_review = [],
      img_terrorism_block = [],
      img_terrorism_review = [],
      asr_political_block = [],
      asr_political_review = [],
      asr_porn_block = [],
      asr_porn_review = [],
      asr_terrorism_block = [],
      asr_terrorism_review = [],
      ocr_political_block = [],
      ocr_political_review = [],
      ocr_porn_block = [],
      ocr_porn_review = [],
      ocr_terrorism_block = [],
      ocr_terrorism_review = [],
    } = auditTimeline;

    const imgCount = [
      ...img_porn_block,
      ...img_porn_review,
      ...img_political_block,
      ...img_political_review,
      ...img_terrorism_block,
      ...img_terrorism_review,
    ].length;

    const ocrCount = [
      ...ocr_political_block,
      ...ocr_political_review,
      ...ocr_porn_block,
      ...ocr_porn_review,
      ...ocr_terrorism_block,
      ...ocr_terrorism_review,
    ].length;

    const asrCount = [
      ...asr_political_block,
      ...asr_political_review,
      ...asr_porn_block,
      ...asr_porn_review,
      ...asr_terrorism_block,
      ...asr_terrorism_review,
    ].length;

    const pornCount = [
      ...(auditFilterSet === 'img' ? [...img_porn_block, ...img_porn_review] : []),
      ...(auditFilterSet === 'asr' ? [...asr_porn_block, ...asr_porn_review] : []),
      ...(auditFilterSet === 'ocr' ? [...ocr_porn_block, ...ocr_porn_review] : []),
    ].length;

    const terrorismCount = [
      ...(auditFilterSet === 'img' ? [...img_terrorism_block, ...img_terrorism_review] : []),
      ...(auditFilterSet === 'asr' ? [...asr_terrorism_block, ...asr_terrorism_review] : []),
      ...(auditFilterSet === 'ocr' ? [...ocr_terrorism_block, ...ocr_terrorism_review] : []),
    ].length;

    const politicalCount = [
      ...(auditFilterSet === 'img' ? [...img_political_block, ...img_political_review] : []),
      ...(auditFilterSet === 'asr' ? [...asr_political_block, ...asr_political_review] : []),
      ...(auditFilterSet === 'ocr' ? [...ocr_political_block, ...ocr_political_review] : []),
    ].length;

    const blockCount = [
      ...(auditFilterSet === 'img' && auditFilterType === 'porn' ? img_porn_block : []),
      ...(auditFilterSet === 'img' && auditFilterType === 'terrorism' ? img_terrorism_block : []),
      ...(auditFilterSet === 'img' && auditFilterType === 'political' ? img_political_block : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'porn' ? asr_porn_block : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'terrorism' ? asr_terrorism_block : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'political' ? asr_political_block : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'porn' ? ocr_porn_block : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'terrorism' ? ocr_terrorism_block : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'political' ? ocr_political_block : []),
    ].length;

    const reviewCount = [
      ...(auditFilterSet === 'img' && auditFilterType === 'porn' ? img_porn_review : []),
      ...(auditFilterSet === 'img' && auditFilterType === 'terrorism' ? img_terrorism_review : []),
      ...(auditFilterSet === 'img' && auditFilterType === 'political' ? img_political_review : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'porn' ? asr_porn_review : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'terrorism' ? asr_terrorism_review : []),
      ...(auditFilterSet === 'asr' && auditFilterType === 'political' ? asr_political_review : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'porn' ? ocr_porn_review : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'terrorism' ? ocr_terrorism_review : []),
      ...(auditFilterSet === 'ocr' && auditFilterType === 'political' ? ocr_political_review : []),
    ].length;

    setSets(sets => {
      const backupSets = [...sets];
      backupSets[0].count = imgCount;
      backupSets[1].count = ocrCount;
      backupSets[2].count = asrCount;
      return backupSets;
    });

    setTypes(types => {
      const backupTypes = [...types];
      backupTypes[0].count = pornCount;
      backupTypes[1].count = terrorismCount;
      backupTypes[2].count = politicalCount;
      return backupTypes;
    });

    setSuggestions(suggestions => {
      const backupSuggestions = [...suggestions];
      backupSuggestions[0].count = blockCount;
      backupSuggestions[1].count = reviewCount;
      return backupSuggestions;
    });
  }, [auditTimeline, auditFilterSet, auditFilterType]);

  return useMemo(
    () => (
      <>
        <div className="section">
          <div className="checkbox-group">
            {sets.map(set => (
              <EleStatButton
                key={set.value}
                count={set.count}
                text={set.label}
                selected={auditFilterSet === set.value}
                onSelect={() => onSelectSet(set.value)}
              />
            ))}
          </div>
          <div className="checkbox-group">
            {types.map(type => (
              <EleStatButton
                key={type.value}
                count={type.count}
                text={type.label}
                selected={auditFilterType === type.value}
                onSelect={() => onSelectType(type.value)}
              />
            ))}
          </div>
          <div className="checkbox-group">
            {suggestions.map(suggestion => (
              <EleStatButton
                key={suggestion.value}
                count={suggestion.count}
                text={suggestion.label}
                selected={auditFilterSuggestion === suggestion.value}
                onSelect={() => onSelectSuggestion(suggestion.value)}
              />
            ))}
          </div>
        </div>
        <AuditList />
      </>
    ),
    [auditTimeline, auditFilterSet, auditFilterType, auditFilterSuggestion, sets, types, suggestions],
  );
};

export default React.memo(AuditFilter);
