import React from 'react';
import { Tabs } from 'tdesign-react';
import ExampleList from './list-tab-example';
import UserList from './list-tab-user';
import LibraryTab from './list-tab-library';
import { isLogin } from '../../util/tools';
import Login from '../../components/login';
import { useLocation, useNavigate } from 'react-router-dom';

const { TabPanel } = Tabs;

function List() {
  const location = useLocation();
  const tab = location.pathname.replace(/\/list\/?/, '') || 'example-video';
  const navigate = useNavigate();
  const login = isLogin();
  return (
    <div className="cva-body-content">
      <div className="cva-content-wrap">
        <Tabs size="large" defaultValue={tab} onChange={(v: any) => navigate(v)} style={{ background: 'transparent' }}>
          <TabPanel value="example-video" label="示例视频" destroyOnHide={false}>
            <ExampleList />
          </TabPanel>
          <TabPanel value="my-video" label="我的视频" destroyOnHide={false}>
            {!login ? <Login /> : <UserList />}
          </TabPanel>
          <TabPanel value="my-ai-library" label="我的智能库">
            {!login ? <Login /> : <LibraryTab />}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default React.memo(List);
