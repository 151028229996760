import { message } from 'tdesign-react';
import { getCookie, getUin, login } from '../util/tools';

const errMap: any = {
  '-406': '登录态失效，请登录后重试',
  '-405': '参数错误',
};

async function request<T, U>(action: string, params: T = {} as T): Promise<U> {
  const uin = getUin();
  const skey = getCookie('skey');
  // 需登陆的接口校验登录态
  // if (!(action === 'describe_common_files' || action === 'describe_common_results') && !(uin && skey)) {
  //   login();
  // }
  const body = {
    version: '1.0',
    password: '',
    eventId: 1,
    timestamp: new Date().getTime(),
    interface: {
      interfaceName: action,
      webToken: uin
        ? {
            uin,
            skey,
          }
        : null,
      para: params,
    },
  };
  const res = await fetch(`https://videoface.avc.qcloud.com/new_demo_ai/`, {
    body: JSON.stringify(body),
    method: 'post',
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
  const data = await res.json();
  if (res.status < 200 || res.status >= 400) {
    return Promise.reject(data);
  }
  if (data.returnCode === 0) {
    return data?.data?.Response?.AiSession || data?.data?.Response;
  }
  const errMsg = errMap[data.returnCode];
  if (errMsg) {
    message('error', errMsg);
  }
  if (data.returnCode === -406) {
    login();
  }
  return Promise.reject(data?.data);
}

/**
 * 查询示例视频列表
 */
export function describeCommonList() {
  return request<void, AiSession.CommonFile[]>('describe_common_files');
}

/**
 * 查询用户上传文件列表
 */
export function describeUserList() {
  return request<any, AiSession.CommonFile[]>('describe_files');
}

/**
 * 查询示例视频详情
 */
export function describeCommonDetail(params: AiSession.DetailReq) {
  return request<AiSession.DetailReq, AiSession.CommonFile[]>('describe_common_results', params).then(res => {
    if (res && Array.isArray(res)) {
      return res;
    } else {
      return [];
    }
  });
}

/**
 * 查询用户上传文件详情
 */
export function describeUserDetail(params: AiSession.DetailReq) {
  return request<AiSession.DetailReq, AiSession.CommonFile[]>('describe_results', params).then(res => {
    if (res && Array.isArray(res)) {
      return res;
    } else {
      return [];
    }
  });
}

/**
 * 获取cos文件上传秘钥
 */
export function describeUploadSign() {
  return request<void, UploadFileSignRes>('describe_upload_sign');
}

/**
 * 上传cos视频到ai体验馆
 */
export function uploadFile(params: UploadInfo) {
  return request<UploadInfo, void>('upload_vod_file', params);
}

/**
 * 删除上传视频
 */
export function deleteFile(params: AiSession.DetailReq) {
  return request<AiSession.DetailReq, void>('delete_vod_file', params);
}

/**
 * 查询用户人脸样本列表
 */
export function describePersonList(params: AiSamplePersonReq) {
  return request<AiSamplePersonReq, { TotalCount: number; PersonSet: AiSamplePerson[] }>(
    'describe_person_samples',
    params,
  );
}

/**
 * 查询示例人脸样本列表
 */
export function describeDemoPersonList(params: AiSamplePersonReq) {
  return request<AiSamplePersonReq, { TotalCount: number; PersonSet: AiSamplePerson[] }>(
    'describe_demo_person_samples',
    params,
  );
}

/**
 * 创建人脸样本
 */
export function createPersonSample(params: CreatePersonReq) {
  return request<CreatePersonReq, void>('create_person_sample', params);
}

/**
 * 删除人脸样本
 */
export function deletePersonSample(params: { PersonId: string }) {
  return request<{ PersonId: string }, void>('delete_person_sample', params);
}

/**
 * 修改人脸样本
 */
export function modifyPersonSample(params: ModifyPersonReq) {
  return request<ModifyPersonReq, void>('modify_person_sample', params);
}

/**
 * 查询关键词样本列表
 */
export function describeWordSamples(params: DescribeWordReq) {
  return request<DescribeWordReq, DescribeWordRes>('describe_word_samples', params);
}

/**
 * 创建关键词样本
 */
export function createWordSamples(params: CreateWordReq) {
  return request<CreateWordReq, void>('create_word_samples', params);
}

/**
 * 修改关键词样本
 */
export function modifyWordSamples(params: ModifyWordReq) {
  return request<ModifyWordReq, void>('modify_word_samples', params);
}

/**
 * 删除关键词样本
 */
export function deleteWordSamples(params: DescribeWordReq) {
  return request<DescribeWordReq, void>('delete_word_samples', params);
}

/**
 * 发起AI处理任务
 */
export function CreateTask(params: CreateTaskReq) {
  return request<CreateTaskReq, CreateTaskRes>('process_media_ai', params);
}

/**
 * 查询任务列表
 */
export function getTaskList() {
  return request<void, GetTaskListRes>('get_task_list');
}

/**
 * 开启封面任务
 */
export function setComment(params: { InputInfo: AiSession.MediaInputInfo }) {
  return request<{ InputInfo: AiSession.MediaInputInfo }, { TaskId: string }>('get_cover', params);
}

/**
 * 查询任务详情
 */
export function describeTaskDetail(params: { TaskId: string }) {
  return request<any, any>('describe_ai_task_detail', params);
}

/**
 * 获取封面url
 */
export function describeCoverUrl(params: { TaskId: string }) {
  return request<any, any>('describe_cover_url', params);
}
