/**
 * @user simxin
 * @desc 识别模块
 */

import React, { useState, useMemo, useEffect } from 'react';
import { Radio } from 'tdesign-react';

import FacePanel from './RecogFace';
import VoicePanel from './RecogVoice';
import RecogText from './RecogText';
import ObjectPanel from './RecogObject';

import { ModuleRecogTab } from '../interface.d';
import { useDispatch } from '../dataflow/context';

const ModuleRecog = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<ModuleRecogTab>('face');
  useEffect(() => {
    dispatch('setSessionTab', activeTab === 'object' ? 'recog_object' : 'recog_face');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return useMemo(
    () => (
      <div className="tab-panel-wrap">
        <Radio.Group value={activeTab} onChange={value => setActiveTab(value as ModuleRecogTab)}>
          <Radio.Button value="face">人脸识别</Radio.Button>
          <Radio.Button value="voice">语音识别</Radio.Button>
          <Radio.Button value="text">文本识别</Radio.Button>
          <Radio.Button value="object">物体识别</Radio.Button>
        </Radio.Group>

        {activeTab === 'face' && (
          <div className="second-tab-content">
            <FacePanel />
          </div>
        )}
        {activeTab === 'voice' && (
          <div className="second-tab-content second-tab-voice-content">
            <VoicePanel />
          </div>
        )}
        {activeTab === 'text' && (
          <div className="second-tab-content">
            <RecogText />
          </div>
        )}
        {activeTab === 'object' && (
          <div className="second-tab-content">
            <ObjectPanel />
          </div>
        )}
      </div>
    ),
    [activeTab],
  );
};

export default React.memo(ModuleRecog);
