/**
 * @user simxin
 * @desc 详情全局数据更新
 */

import { StoreType } from '../interface.d';

const reducer = (state: StoreType, action: any) => {
  switch (action.type) {
    case 'setDetailType':
      return { ...state, detailType: action.value };
    case 'setFileId':
      return { ...state, fileId: action.value };
    case 'setSessionTab':
      return { ...state, sessionTab: action.value };
    case 'setPlayer':
      return { ...state, player: action.value };
    case 'setVideoPlayTime':
      return { ...state, videoPlayTime: action.value };
    case 'setEleVideoPlayer':
      return { ...state, eleVideoPlayer: action.value };
    case 'setAnalyMeta':
      return { ...state, analyMeta: action.value };
    case 'setRecogFace':
      return { ...state, recogFace: action.value };
    case 'setRecogFaceId':
      return { ...state, recogFaceId: action.value };
    case 'setRecogObject':
      return { ...state, recogObject: action.value };
    case 'setRecogObjectId':
      return { ...state, recogObjectId: action.value };
    case 'setRecogSubtitleSwitch':
      return { ...state, recogSubtitleSwitch: action.value };
    case 'setRecogSubtitlePath':
      return { ...state, recogSubtitlePath: action.value };
    case 'setRecogVoiceTotal':
      return { ...state, recogVoiceTotal: action.value };
    case 'setRecogVoiceSnippet':
      return { ...state, recogVoiceSnippet: action.value };
    case 'setRecogTextTotal':
      return { ...state, recogTextTotal: action.value };
    case 'setRecogTextSnippet':
      return { ...state, recogTextSnippet: action.value };
    case 'setAnalyTagWithTime':
      return { ...state, analyTagWithTime: action.value };
    case 'setAnalyTagWithLabel':
      return { ...state, analyTagWithLabel: action.value };
    case 'setAnalyStrip':
      return { ...state, analyStrip: action.value };
    case 'setAnalyHighlight':
      return { ...state, analyHighlight: action.value };
    case 'setAnalyCreditsTitle':
      return { ...state, analyCreditsTitle: action.value };
    case 'setAnalyCopyright':
      return { ...state, analyCopyright: action.value };
    case 'setAnalyAbstract':
      return { ...state, analyAbstract: action.value };
    case 'setAuditTimeline':
      return { ...state, auditTimeline: action.value };
    case 'setAuditFilterType':
      return { ...state, auditFilterType: action.value };
    case 'setAuditFilterSet':
      return { ...state, auditFilterSet: action.value };
    case 'setAuditFilterSuggestion':
      return { ...state, auditFilterSuggestion: action.value };

    default:
      return state;
  }
};

export default reducer;
