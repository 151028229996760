/**
 * @user simxin
 * @desc 版权
 */

import React, { useMemo } from 'react';
import { DisableTab } from '../config';
import { useStore } from '../dataflow/context';
import EleDisableTabIntro from './EleDisableTabIntro';

const AnalyCopyright = () => {
  const { analyCopyright } = useStore();
  return useMemo(
    () =>
      DisableTab.includes('analy_copyright') ? (
        <EleDisableTabIntro />
      ) : (
        <p className="public-desc-text">{analyCopyright || '暂无数据'}</p>
      ),
    [analyCopyright],
  );
};

export default React.memo(AnalyCopyright);
