/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 拆条
 */

import React, { useMemo } from 'react';
import { useStore } from '../dataflow/context';
import EleVideoClip from './EleVideoClip';
import { formatTimePoint } from '../common/util';

const AnalyStrip = () => {
  const { analyStrip, player } = useStore();

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  return useMemo(
    () =>
      analyStrip.length ? (
        <div className="public-video-clip-list">
          {analyStrip.map((item, index) => (
            <div key={index} onClick={() => updatePlayerTime(item.startTime)}>
              <EleVideoClip
                title={`拆条${index + 1}`}
                cover={item.cover}
                descs={[
                  { label: '起始时间', value: formatTimePoint(item.startTime) },
                  { label: '结束时间', value: formatTimePoint(item.endTime) },
                ]}
              />
            </div>
          ))}
        </div>
      ) : (
        <p className="public-desc-text">暂无数据</p>
      ),
    [analyStrip, player],
  );
};

export default React.memo(AnalyStrip);
