import { useNavigate } from 'react-router-dom';
import { IconFont } from 'tdesign-icons-react';
import { Button } from 'tdesign-react';
import TextImg from '../assets/text.png';

export default function Home() {
  const nav = useNavigate();
  return (
    <div className="cva-introduce__body">
      <div className="cva-introduce__content">
        <div className="img__wrap">
          <img src={TextImg} alt="" />
        </div>
        <Button
          className="btn__start"
          variant="outline"
          suffix={<IconFont name="play-circle" />}
          ghost
          onClick={() => nav('/list')}
        >
          开启视频AI分析
        </Button>
      </div>
    </div>
  );
}
