/**
 * @user simxin
 * @desc 生成 Tcplayer 播放器
 */

import { VideoDefaultWidth, VideoDefaultHeight } from '../config';
import { sleep } from './util';

const loadScript = async function (src: string, id: string) {
  if (document.getElementById(id)) return true;
  return new Promise((resolve, reject) => {
    const dom = document.createElement('script');
    dom.id = id;
    dom.src = src;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(true);
    };
    dom.onerror = err => {
      console.error(err);
      reject(false);
    };
    document.body.appendChild(dom);
  });
};

const loadStyle = async function (src: string, id: string) {
  if (document.getElementById(id)) return true;
  return new Promise((resolve, reject) => {
    const dom = document.createElement('link');
    dom.id = id;
    dom.href = src;
    dom.rel = 'stylesheet';
    dom.onload = () => {
      resolve(true);
    };
    dom.onerror = err => {
      console.error(err);
      reject(false);
    };
    document.head.appendChild(dom);
  });
};

const createPlayer = async (id: string) => {
  const options = {
    autoplay: true,
    width: `${VideoDefaultWidth}px`,
    height: `${VideoDefaultHeight}px`,
    playbackRates: [],
  };

  const video = document.getElementById(id);
  if (!video || !['video', 'audio'].includes(video.nodeName.toLowerCase())) return null;

  if ((window as any).TcPlayer) {
    return (window as any)?.TcPlayer(id, options);
  } else {
    const script = '//web.sdk.qcloud.com/player/tcplayer/release/v4.5.4/tcplayer.v4.5.4.min.js';
    const hls = '//web.sdk.qcloud.com/player/tcplayer/release/v4.5.4/libs/hls.min.1.1.5.js';
    const flv = 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.5.4/libs/flv.min.1.6.3.js';
    const style = '//web.sdk.qcloud.com/player/tcplayer/release/v4.5.4/tcplayer.min.css';

    let retry = 100;

    const ret0 = await loadScript(hls, 'tcplayer-hls-id');
    if (ret0) {
      while (!(window as any)?.Hls && retry > 0) {
        retry--;
        console.log('hls:', (window as any)?.Hls);
        await sleep(300);
      }
    } else {
      return null;
    }

    const retFlv = await loadScript(flv, 'tcplayer-flv-id');
    if (retFlv) {
      while (!(window as any)?.flvjs && retry > 0) {
        retry--;
        console.log('flv:', (window as any)?.flvjs);
        await sleep(300);
      }
    } else {
      return null;
    }

    const [ret1, ret2] = await Promise.all([loadScript(script, 'tcplayer-js-id'), loadStyle(style, 'tcplayer-css-id')]);
    if (ret1 && ret2) {
      while (!(window as any)?.TCPlayer && retry > 0) {
        retry--;
        console.log('tcplayer:', (window as any)?.TCPlayer);
        await sleep(300);
      }
      return (window as any)?.TCPlayer(id, options);
    }
  }
};

export { createPlayer };
