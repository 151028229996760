import React, { useEffect, useRef, useState } from 'react';
import { IconFont } from 'tdesign-icons-react';
import { describeCoverUrl, describeUploadSign, setComment, uploadFile } from '../common/api';
import COS from 'cos-js-sdk-v5';
import { message } from 'tdesign-react';
export type UserData = Partial<AiSession.CommonFile & { percent: number }>;

const VideoUpload = (props: { setUploadData: (params: any) => any; getList: () => void; curLength: number }) => {
  const cosSdk = useRef<COS>();
  const formRef = useRef<any>();
  const [cosConfig, setCosConfig] = useState({} as UploadFileSignRes);

  async function handleFileChange() {
    if (!cosSdk.current) return;
    const file = formRef.current.files[0];
    formRef.current.value = null;
    const key = `${cosConfig.Dir}${file.name}`;
    if (props.curLength >= 9) {
      props.setUploadData((list: UserData[]) => [
        {
          FileId: file.lastModified,
          Status: 'error',
        },
        ...list,
      ]);
      setTimeout(
        () => props.setUploadData((list: UserData[]) => list.filter(item => item.FileId !== file.lastModified)),
        2000,
      );
      return;
    }
    function setPercent(percent: number) {
      props.setUploadData((list: UserData[]) => {
        const curIndex = list.findIndex(item => item?.FileId === file.lastModified);
        if (curIndex === -1) {
          list.unshift({
            FileId: file.lastModified,
            Status: 'uploading',
            percent: percent,
            Title: file.name,
            CovUrl: '',
          });
        } else {
          list[curIndex].percent = percent;
        }
        return [...list];
      });
    }
    setPercent(0);
    try {
      // 先将视频存入cos
      const data = await cosSdk.current.putObject({
        Bucket: cosConfig.Bucket,
        Region: cosConfig.Region,
        Key: key,
        Body: file,
        onProgress: ({ percent }) => {
          setPercent(Math.floor((percent * 100) / 2));
        },
      });
      const InputInfo = {
        Type: 'COS',
        CosInputInfo: {
          Bucket: cosConfig.Bucket,
          Region: cosConfig.Region,
          Object: key,
        },
      };
      // 开启生成封面任务
      const { TaskId } = await setComment({ InputInfo });
      // 轮询封面生成结果
      const url = await new Promise<any>((resolve, reject) => {
        let process = 0;
        const interval = setInterval(async () => {
          process = process >= 50 ? 50 : process + 10;
          try {
            const res = await describeCoverUrl({ TaskId });
            const CoverTask = res.WorkflowTask;
            if (CoverTask?.Status === 'PROCESSING') {
              setPercent(process + 50);
            }
            if (CoverTask?.Status === 'FINISH') {
              clearInterval(interval);
              resolve(res.CovUrl);
            }
            if (CoverTask?.Status === 'FAIL') {
              clearInterval(interval);
              reject();
            }
          } catch (_) {
            clearInterval(interval);
            reject();
          }
        }, 1000);
      });
      // 封面获取成功后，拼接 封面 url
      // const url = `https://${cosConfig.Bucket}.cos.${cosConfig.Region}.myqcloud.com${coverData.Output.CoverSet[0]?.CoverPath}`;

      await uploadFile({
        FileId: data.RequestId || '',
        InputInfo,
        Title: file.name,
        CovUrl: url,
      });
    } catch (error) {
      message.error('文件上传失败，请稍后重试', 3000);
    } finally {
      props.setUploadData((list: UserData[]) => {
        return list.filter(item => item?.FileId !== file.lastModified);
      });
      props.getList();
    }
  }

  useEffect(() => {
    describeUploadSign().then(data => {
      setCosConfig(data);
      cosSdk.current = new COS({
        getAuthorization: (_, callback) => {
          callback({
            TmpSecretId: data.TmpSecretId,
            TmpSecretKey: data.TmpSecretKey,
            SecurityToken: data.SessionToken,
            StartTime: data.StartTime,
            ExpiredTime: data.ExpiredTime,
            ScopeLimit: true,
          });
        },
      });
    });
  }, []);
  return (
    <>
      <div className="cva-video-upload">
        <form className="cva-video-form">
          <input
            ref={formRef}
            className="cva-video-input"
            type="file"
            name="file"
            accept=".m3u8,.mov,.mp4,.flv,.avi"
            onChange={handleFileChange}
          />
        </form>

        <div className="upload-icon-wrap">
          <IconFont name="add" style={{ fontSize: '22px', color: '#006eff' }} />
        </div>
        <p className="upload-text">添加视频</p>
      </div>
    </>
  );
};

export default React.memo(VideoUpload);
