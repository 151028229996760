/**
 * @user simxin
 * @desc 任务详情
 */

import React from 'react';
import { DetailProvider } from './dataflow/context';
import DataMount from './dataflow/mount';
import Detail from './page/detail';

const Index = () => {
  return (
    <DetailProvider>
      <DataMount>
        <Detail />
      </DataMount>
    </DetailProvider>
  );
};

export default React.memo(Index);
