import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { describeUserList } from '../../common/api';
import VideoCard from '../../components/VideoCard';
import VideoUpload, { UserData } from '../../components/VideoUpload';

const statusMap: any = {
  FINISH: 'success',
  PROCESSING: 'analyzing',
  WAITING: 'new',
  uploading: 'uploading',
  error: 'error',
};

function UserList() {
  const location = useLocation();
  const [commonList, setCommonList] = useState<AiSession.CommonFile[]>([]);
  const [upLoadData, setUploadData] = useState<UserData[]>([]);
  const showList = useMemo(() => {
    if (upLoadData) {
      return [...upLoadData, ...commonList];
    }
    return commonList;
  }, [commonList, upLoadData]);
  function getList() {
    describeUserList().then(data => data?.length && setCommonList(data));
  }
  useEffect(() => {
    location.pathname === '/list/my-video' && getList();
  }, [location.pathname]);

  return (
    <>
      <div className="cva-home-video-card-list">
        <div className="video-card-wrap">
          <VideoUpload setUploadData={setUploadData} getList={getList} curLength={showList.length} />
        </div>
        {showList?.map?.((item, index) => (
          <div className="video-card-wrap" key={item.FileId}>
            <VideoCard
              data={item}
              status={statusMap[item?.Status || ''] || 'new'}
              getList={getList}
              key={item.FileId}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(UserList);
