/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 语音识别
 */

import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useStore } from '../dataflow/context';
import { Tag, Radio, Switch } from 'tdesign-react';
import { formatTimePoint } from '../common/util';
import { StoreType, RecogVoiceType } from '../interface.d';

const RecogVoice = () => {
  const dispatch = useDispatch();
  const { player, recogSubtitleSwitch, recogVoiceTotal, recogVoiceSnippet, recogSubtitlePath, videoPlayTime } =
    useStore();

  const [activeWord, setActiveWord] = useState<string>('');
  const [activeTab, setActiveTab] = useState<RecogVoiceType>('total');
  const [subtitleTrack, setSubtitleTrack] = useState();
  const refTotal = useRef<HTMLHeadingElement>(null);
  const refSnippet = useRef<HTMLHeadingElement>(null);

  const onChangeSubtitle = (value: boolean) => {
    dispatch('setRecogSubtitleSwitch', value);

    if (player && recogSubtitlePath) {
      if (value) {
        const track = player.addRemoteTextTrack(
          {
            src: recogSubtitlePath.replace(/https?:/, ''),
            kind: 'subtitles',
            srclang: 'zh-cn',
            label: '开启字幕',
            default: 'true',
          },
          false,
        );

        // tcplayer 需要手动点击开启字幕
        track.addEventListener('load', function () {
          const dom: NodeListOf<HTMLElement> = document.querySelectorAll('.vjs-subs-caps-button .vjs-menu-content li');
          if (dom && dom.length > 2) {
            dom[2]?.click();
          }
        });

        setSubtitleTrack(track);
      }

      if (!value && subtitleTrack) {
        player.removeRemoteTextTrack(subtitleTrack);
      }
    }
  };

  const onchangeWord = (value: string) => {
    setActiveWord(value);
  };

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  const checkTimeRange = (
    ref: React.RefObject<HTMLHeadingElement>,
    classname: string,
    index: number,
    start: number,
    end: number,
  ) => {
    if (start === end) {
      end += 0.1;
    }

    if (start <= videoPlayTime && end > videoPlayTime) {
      const parentDom = ref.current;
      const childDoms = parentDom?.getElementsByClassName(classname) as HTMLCollectionOf<HTMLElement>;
      if (parentDom && childDoms && childDoms[index]) {
        parentDom.scrollTo({
          top: childDoms[index]?.offsetTop - parentDom.clientHeight / 3 || 0,
          behavior: 'smooth',
        });
      }
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const sessionTab: StoreType['sessionTab'] =
      activeTab === 'total'
        ? 'recog_voice_total'
        : activeTab === 'snippet'
        ? 'recog_voice_snippet'
        : 'recog_voice_total';
    dispatch('setSessionTab', sessionTab);
  }, [activeTab]);

  useEffect(() => {
    if (recogVoiceSnippet.length) {
      setActiveWord(recogVoiceSnippet[0].word);
    } else {
      setActiveWord('');
    }
  }, [recogVoiceSnippet]);

  return useMemo(
    () => (
      <Fragment>
        <div className="switch-text">
          <p className="title">开启字幕</p>
          <Switch value={recogSubtitleSwitch} onChange={value => onChangeSubtitle(value as boolean)} />
        </div>
        <Radio.Group
          value={activeTab}
          onChange={value => setActiveTab(value as RecogVoiceType)}
          style={{ marginTop: '12px' }}
        >
          <Radio value="total">全文识别</Radio>
          <Radio value="snippet">片段识别</Radio>
        </Radio.Group>
        {activeTab === 'total' ? (
          recogVoiceTotal.length ? (
            <div ref={refTotal} className="public-text-content-wrap">
              {recogVoiceTotal.map((item, index) => (
                <div
                  key={index}
                  className={`recog-voice-total-ref text-item ${
                    checkTimeRange(refTotal, 'recog-voice-total-ref', index, item.startTime, item.endTime)
                      ? ' selected'
                      : ''
                  }`}
                  onClick={() => updatePlayerTime(item.startTime)}
                >
                  <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                  <p className="text">{item.text}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
        {activeTab === 'snippet' ? (
          recogVoiceSnippet.length ? (
            <>
              {recogVoiceSnippet.map((item, index) => (
                <div key={index} className="public-tag-wrap">
                  <Tag.CheckTag checked={item.word === activeWord} onClick={() => onchangeWord(item.word)}>
                    {item.word}
                  </Tag.CheckTag>
                </div>
              ))}
              <div ref={refSnippet} className="public-text-content-wrap">
                {recogVoiceSnippet
                  .find(item => item.word === activeWord)
                  ?.segments.map((item, index) => (
                    <div
                      key={index}
                      className={`recog-voice-snippet-ref text-item text-item__similarity ${
                        checkTimeRange(refSnippet, 'recog-voice-snippet-ref', index, item.startTime, item.endTime)
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => updatePlayerTime(item.startTime)}
                    >
                      <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                      <p className="text">{`相似度 ${item.confidence}%`}</p>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
      </Fragment>
    ),
    [
      player,
      recogSubtitleSwitch,
      recogVoiceTotal,
      recogVoiceSnippet,
      recogSubtitlePath,
      videoPlayTime,
      activeWord,
      activeTab,
      subtitleTrack,
      refTotal,
      refSnippet,
    ],
  );
};

export default React.memo(RecogVoice);
