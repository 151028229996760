import { MAINDOMAIN } from '@src/common/define';

export function getCookie(key: string) {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.replaceAll(' ', '');
    const [keys, value] = cookie.split('=');
    if (key === keys) {
      return value;
    }
  }
  return '';
}

export function getUin() {
  return getCookie('uin')?.replace(/[a-zA-Z]/g, '');
}

export function isLogin() {
  const isLogin = getCookie('uin') && getCookie('skey');
  return isLogin;
}

export function login() {
  window.location.href = `//${MAINDOMAIN}/login?s_url=${encodeURIComponent(window.location?.href)}`;
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });
    reader.readAsDataURL(blob);
  });
}
