import { login } from '@src/util/tools';
import React from 'react';
import { IconFont } from 'tdesign-icons-react';
import { Button } from 'tdesign-react';

function Login() {
  return (
    <div className="cva-home-login-section">
      <div className="icon-section">
        <IconFont name="error" style={{ color: '#006EFF', fontSize: '36px' }} />
      </div>
      <p className="text">需要登录腾讯云后才能管理自己添加的视频</p>
      <Button size="large" onClick={login}>
        立即登录
      </Button>
    </div>
  );
}
export default React.memo(Login);
