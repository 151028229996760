/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 卡片组件
 */

import React, { useMemo } from 'react';
import EleCatchPic from './EleCatchPic';

type EleObjectCardProps = {
  selected: boolean;
  id: string;
  url: string;
  name: string;
  onSelect: (value: string) => void;
};

const EleObjectCard = ({ id, selected, url, name, onSelect }: EleObjectCardProps) => {
  return useMemo(
    () => (
      <div className={`cva-object-card ${selected ? ' selected' : ''}`} onClick={() => onSelect(id)}>
        <EleCatchPic src={url} alt="" />
        <p className="name">{name}</p>
      </div>
    ),
    [id, selected, url, name],
  );
};

export default React.memo(EleObjectCard);
