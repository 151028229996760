/**
 * @user simxin
 * @desc 摘要
 */

import React, { useMemo } from 'react';
import { useStore } from '../dataflow/context';

const AnalyAbstract = () => {
  const { analyAbstract } = useStore();

  return useMemo(
    () =>
      analyAbstract.length ? (
        <div>
          {analyAbstract.map((item, index) => (
            <p key={index} className="public-desc-text">
              {item}
            </p>
          ))}
        </div>
      ) : (
        <p className="public-desc-text">暂无数据</p>
      ),
    [analyAbstract],
  );
};

export default React.memo(AnalyAbstract);
