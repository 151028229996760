/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 播放器组件
 */

import React, { useEffect, useState, useMemo } from 'react';
import { useStore, useDispatch } from '../dataflow/context';
import { createPlayer } from '../common/tcplayer';

const EleVideoPlayer = () => {
  const dispatch = useDispatch();
  const { player, eleVideoPlayer } = useStore();
  const [videoId] = useState(`mps-tcplayer-video-${new Date().getTime()}`);

  useEffect(() => {
    mountVideo();
    return () => unmountVideo();
  }, []);

  useEffect(() => {
    if (player && eleVideoPlayer.url) {
      player.src(eleVideoPlayer.url);
      player.on('timeupdate', updateVideoPlayerTime);
    }
  }, [player, eleVideoPlayer.url]);

  function updateVideoPlayerTime() {
    if (player) {
      dispatch('setVideoPlayTime', player.currentTime());
    }
  }

  async function mountVideo() {
    const player = await createPlayer(videoId);
    dispatch('setPlayer', player);
  }

  function unmountVideo() {
    player?.dispose();
    dispatch('setPlayer', null);
  }

  return useMemo(() => <video id={videoId} preload="auto" />, [player, eleVideoPlayer, videoId]);
};

export default React.memo(EleVideoPlayer);
