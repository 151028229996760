import React from 'react';
import { IconFont } from 'tdesign-icons-react';

const ButtonAdd = (props: { children: string; onClick?: () => any }) => {
  const { children, onClick } = props;

  return (
    <div className="button-add" onClick={onClick}>
      <IconFont name="add" style={{ color: '#006eff', fontSize: '18px' }} />
      <p className="text">{children}</p>
    </div>
  );
};

export default React.memo(ButtonAdd);
