/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 标签
 */

import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { useStore, useDispatch } from '../dataflow/context';
import { Tag, Radio } from 'tdesign-react';
import { formatTimePoint } from '../common/util';
import { StoreType, AnalyTagType } from '../interface.d';

const AnalyTag = () => {
  const dispatch = useDispatch();
  const { player, analyTagWithTime, analyTagWithLabel, videoPlayTime } = useStore();
  const [activeTag, setActiveTag] = useState<string>('');
  const [activeTab, setActiveTab] = useState<AnalyTagType>('smart');
  const refSmart = useRef<HTMLHeadingElement>(null);
  const refFixed = useRef<HTMLHeadingElement>(null);

  const onChangeWord = (value: string) => {
    setActiveTag(value);
  };

  const updatePlayerTime = (seconds: number) => {
    player?.currentTime(seconds);
  };

  const checkTimeRange = (
    ref: React.RefObject<HTMLHeadingElement>,
    classname: string,
    index: number,
    start: number,
    end: number,
  ) => {
    if (start === end) {
      end += 0.1;
    }

    if (start <= videoPlayTime && end > videoPlayTime) {
      const parentDom = ref.current;
      const childDoms = parentDom?.getElementsByClassName(classname) as HTMLCollectionOf<HTMLElement>;
      if (parentDom && childDoms && childDoms[index]) {
        parentDom.scrollTo({
          top: childDoms[index]?.offsetTop - parentDom.clientHeight / 3 || 0,
          behavior: 'smooth',
        });
      }
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const sessionTab: StoreType['sessionTab'] =
      activeTab === 'smart' ? 'analy_tag_smart' : activeTab === 'fixed' ? 'analy_tag_fixed' : 'analy_tag_smart';
    dispatch('setSessionTab', sessionTab);
  }, [activeTab]);

  useEffect(() => {
    if (analyTagWithLabel.length) {
      setActiveTag(analyTagWithLabel[0].tag);
    } else {
      setActiveTag('');
    }
  }, [analyTagWithLabel]);

  return useMemo(
    () => (
      <Fragment>
        <Radio.Group value={activeTab} onChange={value => setActiveTab(value as AnalyTagType)}>
          <Radio value="smart">智能标签</Radio>
          <Radio value="fixed">标签匹配</Radio>
        </Radio.Group>
        {activeTab === 'smart' ? (
          analyTagWithTime.length ? (
            <div ref={refSmart} className="public-text-content-wrap">
              {analyTagWithTime.map((item, index) => (
                <div
                  key={index}
                  className={`analy-tag-smart-ref text-item text-item__tag ${
                    checkTimeRange(refSmart, 'analy-tag-smart-ref', index, item.startTime, item.endTime)
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => updatePlayerTime(item.startTime)}
                >
                  <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                  <div className="tag-wrap">
                    {item.tags.map(info => (
                      <Tag key={info.tag}>{info.tag}</Tag>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
        {activeTab === 'fixed' ? (
          analyTagWithLabel.length ? (
            <>
              <div className="public-tag-wrap">
                {analyTagWithLabel.map((item, index) => (
                  <Tag.CheckTag key={index} checked={item.tag === activeTag} onClick={() => onChangeWord(item.tag)}>
                    {item.tag}
                  </Tag.CheckTag>
                ))}
              </div>
              <div ref={refFixed} className="public-text-content-wrap">
                {analyTagWithLabel
                  .find(item => item.tag === activeTag)
                  ?.times.map((item, index) => (
                    <div
                      key={index}
                      className={`analy-tag-smart-ref text-item text-item__similarity ${
                        checkTimeRange(refFixed, 'analy-tag-smart-ref', index, item.startTime, item.endTime)
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => updatePlayerTime(item.startTime)}
                    >
                      <p className="time">{`${formatTimePoint(item.startTime)} - ${formatTimePoint(item.endTime)}`}</p>
                      <p className="text">{`相似度 ${item.confidence}%`}</p>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <p className="public-desc-text">暂无数据</p>
          )
        ) : null}
      </Fragment>
    ),
    [player, analyTagWithTime, analyTagWithLabel, videoPlayTime, activeTag, activeTab, refSmart, refFixed],
  );
};

export default React.memo(AnalyTag);
