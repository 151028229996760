import React, { useEffect, useRef } from 'react';
import { Button, Alert, DialogPlugin, Dialog, message } from 'tdesign-react';
import { IconFont } from 'tdesign-icons-react';

import videoJpg from '../assets/video.jpg';
import { CreateTask, deleteFile, describeTaskDetail } from '../common/api';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { UserData } from './VideoUpload';

/* 未经分析 | 正在分析 | 分析成功 | 出错 */
type VideoStatus = 'new' | 'analyzing' | 'success' | 'error' | 'uploading';

const VideoCard = (props: { data?: UserData; status?: VideoStatus; getList?: () => void }) => {
  const publicPerson = !!+(localStorage.getItem('ai-library-public-person') || '1');
  const navigator = useNavigate();
  const { status = '', data, getList } = props;
  const [process, setProcess] = useState(0);
  const [visible, setVisible] = useState(false);
  const interval = useRef(-1);

  function deleteVideo() {
    const mydialog = DialogPlugin({
      header: '删除视频',
      body: `确认删除视频 ${data?.Title} 吗？删除后数据将无法恢复。`,
      onConfirm: () => {
        deleteFile({ FileId: data?.FileId || '' }).then(() => {
          getList?.();
          mydialog.hide();
        });
      },
      onClose: () => {
        mydialog.hide();
      },
    });
  }
  function askModal() {
    setVisible(true);
  }
  function startTask() {
    CreateTask({
      FileId: data?.FileId!,
      InputInfo: data?.InputInfo!,
      RecognitionUseDefault: publicPerson ? 1 : 0,
      // AiContentReviewTask: { Definition: 30 },
      // AiAnalysisTask: { Definition: 30 },
      // AiRecognitionTask: { Definition: isUsePublic ? 20 : 30 },
    })
      .then(() => {
        setVisible(false);
        getList?.();
      })
      .catch(() => {
        message('error', '开启分析任务失败，请稍后重试');
      });
  }
  function clearInter() {
    clearInterval(interval.current);
    interval.current = -1;
  }
  function getProcess(AlResult = {} as any) {
    if (!status) return 0;
    const processList = [
      ...(AlResult.AiAnalysisResultSet ?? []),
      ...(AlResult.AiContentReviewResultSet ?? []),
      ...(AlResult.AiRecognitionResultSet ?? []),
    ].map(item => {
      if (item) {
        for (const key in item) {
          if (key && key.includes('Task')) {
            return item?.[key]?.Progress || 0;
          }
        }
      }
      return 0;
    });
    const aver = Math.floor(processList.reduce((last, now) => last + now, 0) / processList.length);
    return aver;
  }
  useEffect(() => {
    if (interval.current !== -1) {
      clearInter();
    }
    // 处于分析中的任务，需轮询获取任务分析进度
    if (status === 'analyzing') {
      const getProcessFuc = () => {
        describeTaskDetail({ TaskId: data?.AnalysisTaskid! })
          .then(res => {
            if (res.Status === 'FINISH') {
              clearInter();
              getList?.();
            } else {
              const AlResult = res.WorkflowTask || {};
              setProcess(getProcess(AlResult));
            }
          })
          .catch(err => {
            console.log(err);
            clearInter();
          });
      };
      getProcessFuc();
      interval.current = +setInterval(getProcessFuc, 5000);
    }
    return () => {
      interval.current !== -1 && clearInter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="cva-video-card">
      <Dialog
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={startTask}
        header="提示"
        confirmBtn="开始分析"
        destroyOnClose
      >
        <div>请点击开始分析（已有的结果将会被覆盖）</div>
      </Dialog>
      {status !== 'analyzing' && status !== 'error' && status !== 'uploading' && (
        <div className="cover-wrap">
          <img src={data?.CovUrl || videoJpg} alt="" />
        </div>
      )}
      {status && (
        <div className="button-wrap">
          {status === 'new' && (
            <Button icon={<IconFont name="play-circle" />} onClick={askModal}>
              开始智能分析
            </Button>
          )}
          {status === 'success' && (
            <>
              <Button
                icon={<IconFont name="browse" />}
                onClick={() =>
                  navigator(`/detail?type=user&fileId=${data?.FileId}&taskId=${data?.AnalysisTaskid}`, {
                    state: data,
                  })
                }
              >
                查看结果
              </Button>
              <Button icon={<IconFont name="refresh" />} onClick={askModal}>
                重新分析
              </Button>
            </>
          )}
        </div>
      )}
      {status && status === 'analyzing' && <p className="analyze-info">{`视频分析中...(${process}%)`} </p>}
      {status && status === 'uploading' && (
        <p className="analyze-info">
          视频上传中...(
          {
            // @ts-ignore
            data?.percent
          }
          % )
        </p>
      )}
      {status && status === 'error' && (
        <div className="error-tips-wrap">
          <Alert theme="warning" message="最大可上传9条视频，当前无法上传" />
        </div>
      )}
      {status !== 'error' && status !== 'analyzing' && (
        <div className="name-wrap">
          <p className="name">{data?.Title}</p>
          {status && status !== 'uploading' && (
            <p className="delete-btn">
              <IconFont name="delete" onClick={deleteVideo} style={{ color: '#FFFFFF' }} />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(VideoCard);
