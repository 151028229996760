/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 对象识别
 */

import React, { Fragment, useMemo } from 'react';
import { DisableTab } from '../config';
import { useDispatch, useStore } from '../dataflow/context';
import EleDisableTabIntro from './EleDisableTabIntro';
import EleObjectCard from './EleObjectCard';
import EleGraphCard from './EleGraphCard';
import { RecogDetailCtx } from '../interface.d';

const RecogObject = () => {
  const { recogObject, recogObjectId } = useStore();
  const recogObjectList = recogObject.recogDetailCtx || [];
  const dispatch = useDispatch();

  const onSelect = (value: string) => {
    dispatch('setRecogObjectId', value);
  };

  return useMemo(
    () =>
      DisableTab.includes('recog_object') ? (
        <EleDisableTabIntro />
      ) : recogObjectList.length ? (
        <Fragment>
          <div className="public-object-list">
            {recogObjectList.map(item => (
              <div className="object-card-wrap" key={item.id}>
                <EleObjectCard
                  id={item.id!}
                  name={item.name}
                  url={item.url}
                  onSelect={onSelect}
                  selected={item.id === recogObjectId}
                />
              </div>
            ))}
          </div>
          {recogObjectId ? (
            <div className="public-object-detail">
              <EleGraphCard info={recogObjectList.find(item => item.id === recogObjectId) as RecogDetailCtx} />
            </div>
          ) : null}
        </Fragment>
      ) : (
        <p className="public-desc-text">
          {recogObject.status === 'FAIL'
            ? '当前识别任务异常，请重试'
            : '暂无识别结果，您可以更新智能库或者源视频后重试'}
        </p>
      ),
    [recogObject, recogObjectId],
  );
};

export default React.memo(RecogObject);
