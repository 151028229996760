/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 人脸识别
 */

import React, { Fragment, useMemo } from 'react';
import { useDispatch, useStore } from '../dataflow/context';
import EleObjectCard from './EleObjectCard';
import EleGraphCard from './EleGraphCard';
import { RecogDetailCtx } from '../interface.d';

const RecogFace = () => {
  const { recogFace, recogFaceId } = useStore();
  const recogFaceList = recogFace.recogDetailCtx || [];
  const dispatch = useDispatch();

  const onSelect = (value: string) => {
    dispatch('setRecogFaceId', value);
  };

  return useMemo(
    () =>
      recogFaceList.length ? (
        <Fragment>
          <div className="public-object-list">
            {recogFaceList.map(item => (
              <div className="object-card-wrap" key={item.id}>
                <EleObjectCard
                  id={item.id!}
                  name={item.name}
                  url={item.url}
                  onSelect={onSelect}
                  selected={item.id === recogFaceId}
                />
              </div>
            ))}
          </div>
          {recogFaceId ? (
            <div className="public-object-detail">
              <EleGraphCard info={recogFaceList.find(item => item.id === recogFaceId) as RecogDetailCtx} />
            </div>
          ) : null}
        </Fragment>
      ) : (
        <p className="public-desc-text">
          {recogFace.status === 'FAIL' ? '当前识别任务异常，请重试' : '暂无识别结果，您可以更新智能库或者源视频后重试'}
        </p>
      ),
    [recogFace, recogFaceId],
  );
};

export default React.memo(RecogFace);
