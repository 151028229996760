/**
 * @user simxin
 * @desc 审核模块
 */

import React, { useMemo } from 'react';

import AuditFilter from './AuditFilter';

const ModuleAudit = () => {
  return useMemo(
    () => (
      <div className="tab-panel-wrap tab-panel-wrap-audit">
        <AuditFilter />
      </div>
    ),
    [],
  );
};

export default React.memo(ModuleAudit);
