import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { describeCommonList } from '../../common/api';
import { useAsync } from '../../components/common-hook';
import VideoCard from '../../components/VideoCard';

function ExampleList() {
  const navigator = useNavigate();
  const location = useLocation();
  const [commonList = []] = useAsync(() => describeCommonList(), [location.pathname]);
  return (
    <>
      <div className="cva-home-video-card-list">
        {commonList?.map(item => (
          <div
            key={item.FileId}
            className="video-card-wrap"
            onClick={() =>
              navigator(`/detail?type=example&fileId=${item.FileId}&taskId=${item.AnalysisTaskid}`, {
                state: item,
              })
            }
          >
            <VideoCard data={item} key={item.FileId} />
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(ExampleList);
