/**
 * @user simxin
 * @desc 工具方法
 */

/**
 * @desc 格式化时间点为 “0000:00:00.000”
 * @params
 *  value: 秒
 */
export const formatTimePoint = (value: number) => {
  const seconds = value < 0 ? 0 : value;
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds / 60) % 60);
  const h = Math.floor(seconds / 3600);
  let str_s = `${s}`;
  let str_m = `${m}`;
  let str_h = `${h}`;

  if (isNaN(seconds) || seconds === Infinity) {
    str_h = str_m = str_s = '-';
  }

  str_h = (h > 0 ? str_h : '00') + ':';
  str_m = (m < 10 ? '0' + m : str_m) + ':';
  str_s = s < 10 ? '0' + s : str_s;

  return str_h + str_m + str_s;
};

/**
 * @desc 获取对应分和秒
 * @params
 *  value: 秒
 */
export function getMinuteAndSecond(value: number) {
  let minute = 0;
  let second = 0;
  if (value >= 60) {
    minute = ~~(value / 60);
  }
  if (value % 60 !== 0) {
    second = ~~(value % 60);
  }
  return { minute, second };
}

/**
 * @desc 格式化视频时间
 * @params
 *  value: 秒
 */
export function formatTimeMss(value: number) {
  const { minute, second } = getMinuteAndSecond(value);
  return `${minute}:${second < 10 ? '0' : ''}${second}`;
}

/**
 * @desc 休眠
 * @params
 *  value: 毫秒
 */
export async function sleep(value: number) {
  return new Promise(resolve => {
    setTimeout(resolve, value);
  });
}
