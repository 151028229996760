/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @user simxin
 * @desc 详情全局数据挂载
 */

import React, { Fragment, useEffect, useMemo } from 'react';
import { MessagePlugin } from 'tdesign-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { describeDetail } from '../common/request';
import { useDispatch } from './context';
import initStore from './store';
import { useAsync } from '@src/components/common-hook';
import { StoreType } from '../interface.d';

type DataMountProps = {
  children: React.ReactNode;
};

const DataMount = ({ children }: DataMountProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const detailType = searchParams.get('type') as StoreType['detailType'];
  const fileId = searchParams.get('fileId') as StoreType['fileId'];

  useEffect(() => {
    dispatch('setFileId', fileId);
    dispatch('setDetailType', detailType);

    return () => unmountData();
  }, []);

  async function mountData() {
    if (!fileId || !detailType) return;

    const ret: StoreType | null = await describeDetail({
      type: detailType,
      params: { FileId: fileId },
    });

    if (!ret) {
      MessagePlugin.warning('文件信息不存在');
      navigator('/list');
      return;
    }
    dispatchData(ret);
  }

  function unmountData() {
    dispatchData(initStore);
  }

  function dispatchData(store: StoreType) {
    const {
      eleVideoPlayer,
      analyMeta,
      recogFace,
      recogFaceId,
      recogObject,
      recogObjectId,
      recogSubtitlePath,
      recogVoiceTotal,
      recogVoiceSnippet,
      recogTextTotal,
      recogTextSnippet,
      analyTagWithTime,
      analyTagWithLabel,
      analyStrip,
      analyHighlight,
      analyCreditsTitle,
      analyCopyright,
      analyAbstract,
      auditTimeline,
    } = store;

    dispatch('setEleVideoPlayer', eleVideoPlayer);
    dispatch('setAnalyMeta', analyMeta);
    dispatch('setRecogFace', recogFace);
    dispatch('setRecogFaceId', recogFaceId);
    dispatch('setRecogObject', recogObject);
    dispatch('setRecogObjectId', recogObjectId);
    dispatch('setRecogSubtitlePath', recogSubtitlePath);
    dispatch('setRecogVoiceTotal', recogVoiceTotal);
    dispatch('setRecogVoiceSnippet', recogVoiceSnippet);
    dispatch('setRecogTextTotal', recogTextTotal);
    dispatch('setRecogTextSnippet', recogTextSnippet);
    dispatch('setAnalyTagWithTime', analyTagWithTime);
    dispatch('setAnalyTagWithLabel', analyTagWithLabel);
    dispatch('setAnalyStrip', analyStrip);
    dispatch('setAnalyHighlight', analyHighlight);
    dispatch('setAnalyCreditsTitle', analyCreditsTitle);
    dispatch('setAnalyCopyright', analyCopyright);
    dispatch('setAnalyAbstract', analyAbstract);
    dispatch('setAuditTimeline', auditTimeline);
  }

  useAsync(() => mountData(), []);

  return useMemo(() => <Fragment>{children}</Fragment>, [searchParams]);
};

export default React.memo(DataMount);
