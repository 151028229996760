/**
 * @user simxin
 * @desc 元信息
 */

import React, { ReactNode, useMemo } from 'react';
import { BrowseIcon } from 'tdesign-icons-react';
import { ImageViewer, Tag, Image } from 'tdesign-react';
import { useStore } from '../dataflow/context';

const AnalyMeta = () => {
  const { analyMeta } = useStore();

  return useMemo(
    () => (
      <div className="analyze-result">
        <div className="tag-section">
          <p className="title">视频分类</p>
          <div className="tag-wrap">
            {analyMeta.classes.map((item, index) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </div>
        </div>
        <div className="tag-section">
          <p className="title">视频标签</p>
          <div className="tag-wrap">
            {analyMeta.tags.map((item, index) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </div>
        </div>
        <div className="cover-wrap">
          <p className="title">视频封面</p>
          <div className="img-list">
            {analyMeta.covers.map((item, index) => {
              const trigger: any = ({ onOpen = () => {} }): ReactNode => {
                const mask = (
                  <div
                    style={{
                      background: 'rgba(0,0,0,.6)',
                      color: '#fff',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={onOpen}
                  >
                    <span>
                      <BrowseIcon size="20px" name={'browse'} /> 预览
                    </span>
                  </div>
                );

                return <Image src={item} overlayContent={mask} overlayTrigger="hover" fit="contain" />;
              };

              return <ImageViewer key={index} trigger={trigger} images={analyMeta.covers} defaultIndex={index} />;
            })}
          </div>
        </div>
      </div>
    ),
    [analyMeta],
  );
};

export default React.memo(AnalyMeta);
